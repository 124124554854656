import * as React from "react";
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useLocation } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { IoMdEyeOff } from "react-icons/io";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#f1f1f5",
		color: "#000",
		fontSize: 15,
		fontWeight: 700,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 16,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: "white",
	},
}));

export default function TicketsTable(props) {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const location = useLocation();
	const [showDetails, setShowDetails] = React.useState(false);
	const [activeID, setActiveID] = React.useState(null);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const renderStatusPills = (status) => {
		switch (status) {
			case 0:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffebeb", fontSize:"10px", fontWeight:700 }} label="PENDING" size="small" color="warning" />;
			case 1:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ebefff", fontSize:"10px", fontWeight:700 }} label="IN PROGRESS" size="small" color="primary" />;
			case 2:
				return <Chip variant="outlined" sx={{ backgroundColor: "#edfff2", fontSize:"10px", fontWeight:700 }} label="FIXED" size="small" color="success" />;
			default:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffeded", fontSize:"10px", fontWeight:700 }} label="DELAYED" size="small" color="danger" />;
		}
	};

	const renderSeverityPills = (status) => {
		switch (status) {
			case 0:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffebeb", fontSize:"10px", fontWeight:700 }} label="MINOR" size="small" color="warning" />;
			case 1:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ebefff", fontSize:"10px", fontWeight:700 }} label="MEDIUM" size="small" color="primary" />;
			case 2:
				return <Chip variant="outlined" sx={{ backgroundColor: "#edfff2", fontSize:"10px", fontWeight:700 }} label="MAJOR" size="small" color="success" />;
			default:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffeded", fontSize:"10px", fontWeight:700 }}label="IMPORTANT" size="small" color="danger" />;
		}
	};
	const toggleDetailsView = (rowId) => {
		setActiveID(rowId);
		setShowDetails(!showDetails);
	};

	const handleAction = (event, details) => {
		props.setDetails(details)
		props.handleClickPopOver(event)
	}

	return (
		<>
			<TableContainer component={Paper} sx={{ display: { xs: "none", md: "block" } }}>
				<Table sx={{ minWidth: 700 }} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Ticket</StyledTableCell>
							<StyledTableCell align="left">Applicant</StyledTableCell>
							<StyledTableCell align="left">Property</StyledTableCell>
							<StyledTableCell align="left">Stage</StyledTableCell>
							<StyledTableCell align="left">Severity</StyledTableCell>
							{location.pathname === "/tickets" && (
								<>
									<StyledTableCell align="left">Contact</StyledTableCell>
									<StyledTableCell align="left">Date Logged</StyledTableCell>
								</>
							)}
							<StyledTableCell align="left">Actions</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.isLoadingRentalTickets ? (
							<StyledTableRow></StyledTableRow>
						) : (
							<>
								{(props.rows.length > 0 ? props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
									<StyledTableRow key={row.id}>
										<StyledTableCell component="th" scope="row">{row.title}</StyledTableCell>
										<StyledTableCell align="left">{row.related_tenant.first_name}</StyledTableCell>
										<StyledTableCell align="left">{row.related_rental_unit.unit_name}</StyledTableCell>
										<StyledTableCell align="left">{renderStatusPills(row.status)}</StyledTableCell>

										<StyledTableCell align="left">{renderSeverityPills(row.severity)}</StyledTableCell>
										{location.pathname === "/tickets" && (
											<>
												<StyledTableCell align="left">{row.related_tenant.first_name}</StyledTableCell>
												<StyledTableCell align="left">{moment(props.formatDate(row.date_created)).format("Do MMMM YYYY")}</StyledTableCell>
											</>
										)}
										<StyledTableCell align="left">
											<IconButton color="primary" size="small" aria-describedby={props.id} onClick={(e) => handleAction(e, row)}>
												<MoreHorizIcon />
											</IconButton>
										</StyledTableCell>
									</StyledTableRow>
								)):(
										<StyledTableRow>
											<StyledTableCell colSpan={6}> No Tickets Available </StyledTableCell>
										</StyledTableRow>
									))}
							</>
						)}
					</TableBody>
				</Table>
				<TablePagination
					component="div"
					colSpan={12}
					count={props.rows.length}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Rows per page" // Custom label for "Rows per page"
					sx={{
						// Styles for the TablePagination component itself
						"& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input.css-baf1rs-MuiInputBase-root-MuiTablePagination-select": {
							marginRight: "5px",
						},
						"& .MuiTablePagination-actions": {
							marginLeft: 0,
						},
						"& .MuiTablePagination-displayedRows": {
							fontSize: "1rem",
							margin: 0,
						},
						"& .MuiSelect-select.MuiInputBase-input": {
							fontSize: "1rem",
							marginTop: "10px",
						},
						"& .MuiTablePagination-selectLabel": {
							fontSize: "1rem",
						},
						"& .MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select": {
							marginTop: "4px",
						},
					}}
				/>
			</TableContainer>

			{/* MOBILE */}
			<TableContainer sx={{ display: { xs: "block", md: "none" } }} component={Paper}>
				<Table aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Ticket</StyledTableCell>
							<StyledTableCell align="center">Actions</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.isLoadingRentalTickets ? (
							<StyledTableRow></StyledTableRow>
						) : (
							<>
								{(props.rows.length > 0 ? props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
									<StyledTableRow key={row.id}>
										<StyledTableCell align="left">
											<span>{row.title}</span>
											<Stack spacing={1} style={{ fontSize: "16px", marginTop: "7px" }}>
												<span style={{ cursor: "pointer", display: "flex", alignItems: "center", fontSize: "15px" }} onClick={() => toggleDetailsView(row.id)}>
													See{" "}
													{activeID === row.id && showDetails ? (
														<>
															{" "}
															less <IoMdEyeOff style={{ marginLeft: "5px", marginTop: "1px" }} />{" "}
														</>
													) : (
														<>
															{" "}
															more <FaRegEye style={{ marginLeft: "5px", marginTop: "1px" }} />{" "}
														</>
													)}
												</span>
												{activeID === row.id && showDetails ? (
													<>
														<span>
															<strong>Applicant:</strong> {row.related_tenant.first_name}
														</span>
														<span>
															<strong>Property:</strong> {row.related_rental_unit.unit_name}
														</span>
														<span>
															<strong>Stage:</strong> {renderStatusPills(row.status)}
														</span>
														<span>
															<strong>Severity:</strong> {renderSeverityPills(row.severity)}
														</span>
														<span>
															<strong>Contact:</strong> {row.related_tenant.first_name}
														</span>
														<span>
															<strong>
																Logged on:
																<br />
															</strong>{" "}
															{moment(props.formatDate(row.date_created)).format("Do MMMM YYYY")}
														</span>
													</>
												) : null}
											</Stack>
										</StyledTableCell>
										<StyledTableCell align="center" sx={{ width: "1%" }}>
											<IconButton color="primary" size="small" aria-describedby={props.id} onClick={(e) => handleAction(e, row)}>
												<MoreHorizIcon />
											</IconButton>
										</StyledTableCell>
									</StyledTableRow>
								)):(
										<StyledTableRow>
											<StyledTableCell colSpan={6}> No Tickets Available </StyledTableCell>
										</StyledTableRow>
									))}
							</>
						)}
					</TableBody>
				</Table>
				<TablePagination
					component="div"
					count={props.rows.length}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Rows" // Custom label for "Rows per page"
					sx={{
						// Styles for the TablePagination component itself
						"& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input.css-baf1rs-MuiInputBase-root-MuiTablePagination-select": {
							marginRight: "5px",
						},
						"& .MuiTablePagination-actions": {
							marginLeft: 0,
						},
						"& .MuiTablePagination-displayedRows": {
							fontSize: "0.95rem",
							margin: 0,
						},
						"& .MuiSelect-select.MuiInputBase-input": {
							fontSize: "0.95rem",
							marginTop: "5px",
						},
						"& .MuiTablePagination-selectLabel": {
							fontSize: "0.95rem",
						},
						"& .MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select": {
							marginTop: "4px",
						},
					}}
				/>
			</TableContainer>
		</>
	);
}
