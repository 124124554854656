import * as React from "react";
import { Stack} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { FaRegEye } from "react-icons/fa";
import { IoMdEyeOff } from "react-icons/io";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#f1f1f5",
		color: "#000",
		fontSize: 15,
		fontWeight: 700,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 16,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: "white",
	},
	// // hide last border
	// "&:last-child td, &:last-child th": {
	// 	border: 0,
	// },
}));


export default function SmartLocksTable(props) {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [showDetails, setShowDetails] = React.useState(false);
	const [activeID, setActiveID] = React.useState(null);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	function toSentenceCase(str) {
		if (!str) return str;
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	}

	const toggleDetailsView = (rowId) => {
		setActiveID(rowId);
		setShowDetails(!showDetails);
	};

	const handleAction = (event, rowid, details) => {
		props.handleClickPopOver(event, rowid);
	};

	return (
		<>
			<TableContainer component={Paper} sx={{ display: { xs: "none", md: "block" } }}>
				<Table sx={{ minWidth: 700 }} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Lock ID</StyledTableCell>
							<StyledTableCell align="left">Manufacturer</StyledTableCell>
							<StyledTableCell align="left">Door Position</StyledTableCell>
							<StyledTableCell align="left">Unit Name</StyledTableCell>
							<StyledTableCell align="left">Status</StyledTableCell>
							<StyledTableCell align="left">Actions</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.isLoadingRentalUnits ? (
							<StyledTableRow></StyledTableRow>
						) : (
							<>
								{(rowsPerPage > 0 ? props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : props.rows).map((row) => (
									<StyledTableRow key={row.id}>
										<StyledTableCell component="th" scope="row">
											{row.related_rental_unit.unit_name}
										</StyledTableCell>
										<StyledTableCell align="left">{toSentenceCase(row.related_rental_unit.unit_type)}</StyledTableCell>
										<StyledTableCell align="left">{row.related_rental_unit.unit_size}</StyledTableCell>
										<StyledTableCell align="left">{row.rent_currency}</StyledTableCell>
										<StyledTableCell align="left">{row.unit_rent}</StyledTableCell>
										<StyledTableCell>
											<IconButton color="primary" size="small" aria-describedby={props.id} onClick={(e) => handleAction(e, row.id, row)}>
												<MoreHorizIcon />
											</IconButton>
										</StyledTableCell>
									</StyledTableRow>
								))}
							</>
						)}
					</TableBody>
					<TableFooter>
						<TableRow></TableRow>
					</TableFooter>
				</Table>
				<TablePagination
					component="div"
					colSpan={12}
					count={props.rows.length}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Rows per page" // Custom label for "Rows per page"
					sx={{
						// Styles for the TablePagination component itself
						"& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input.css-baf1rs-MuiInputBase-root-MuiTablePagination-select": {
							marginRight: "5px",
						},
						"& .MuiTablePagination-actions": {
							marginLeft: 0,
						},
						"& .MuiTablePagination-displayedRows": {
							fontSize: "1rem",
							margin: 0,
						},
						"& .MuiSelect-select.MuiInputBase-input": {
							fontSize: "1rem",
							marginTop: "10px",
						},
						"& .MuiTablePagination-selectLabel": {
							fontSize: "1rem",
						},
						"& .MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select": {
							marginTop: "4px",
						},
					}}
				/>
			</TableContainer>

			{/* MOBILE */}
			<TableContainer sx={{ display: { xs: "block", md: "none" } }} component={Paper}>
				<Table aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Lock ID</StyledTableCell>
							<StyledTableCell align="right">Actions</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.isLoadingRentalUnits ? (
							<StyledTableRow></StyledTableRow>
						) : (
							<>
								{(rowsPerPage > 0 ? props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : props.rows).map((row) => (
									<StyledTableRow key={row.id}>
										<StyledTableCell align="left">
											<span>{row.related_rental_unit.unit_name}</span>
											<Stack spacing={1} style={{ fontSize: "16px", marginTop: "7px" }}>
												<span style={{ cursor: "pointer", display: "flex", alignItems: "center", fontSize: "15px" }} onClick={() => toggleDetailsView(row.id)}>
													See{" "}
													{activeID === row.id && showDetails ? (
														<>
															less <IoMdEyeOff style={{ marginLeft: "5px", marginTop: "1px" }} />
														</>
													) : (
														<>
															more <FaRegEye style={{ marginLeft: "5px", marginTop: "1px" }} />
														</>
													)}
												</span>
												{activeID === row.id && showDetails && (
													<>
														<span>
															{" "}
															<strong>MAnufacturer:</strong> {toSentenceCase(row.related_rental_unit.unit_type)}
														</span>
														<span>
															<strong>Door Position:</strong> {toSentenceCase(row.related_rental_unit.unit_size)}
														</span>
														<span>
															<strong>Unit Name:</strong> {row.rent_currency}
														</span>
														<span>
															<strong>Status:</strong> {row.unit_rent}
														</span>
													</>
												)}
											</Stack>
										</StyledTableCell>
										<StyledTableCell align="center" sx={{ width: "1%" }}>
											<IconButton color="primary" size="small" aria-describedby={props.id} onClick={(e) => props.handleClickPopOver(e, row.id)}>
												<MoreHorizIcon />
											</IconButton>
										</StyledTableCell>
									</StyledTableRow>
								))}
							</>
						)}
					</TableBody>
					<TableFooter>
						<TableRow></TableRow>
					</TableFooter>
				</Table>
				<TablePagination
					component="div"
					count={props.rows.length}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Rows" // Custom label for "Rows per page"
					sx={{
						// Styles for the TablePagination component itself
						"& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input.css-baf1rs-MuiInputBase-root-MuiTablePagination-select": {
							marginRight: "5px",
						},
						"& .MuiTablePagination-actions": {
							marginLeft: 0,
						},
						"& .MuiTablePagination-displayedRows": {
							fontSize: "0.95rem",
							margin: 0,
						},
						"& .MuiSelect-select.MuiInputBase-input": {
							fontSize: "0.95rem",
							marginTop: "5px",
						},
						"& .MuiTablePagination-selectLabel": {
							fontSize: "0.95rem",
						},
						"& .MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select": {
							marginTop: "4px",
						},
					}}
				/>
			</TableContainer>
		</>
	);
}
