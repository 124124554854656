import * as React from "react";
import { Button, Stack, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { IoMdEyeOff } from "react-icons/io";
import { currencyFormatter } from "utilities/currencyFormatter";
import { setOccupancyId } from "state";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#f1f1f5",
		color: "#000",
		fontSize: 15,
		fontWeight: 700,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 16,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: "white",
	},
	// // hide last border
	// "&:last-child td, &:last-child th": {
	// 	border: 0,
	// },
}));


function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

export default function DashboardPropertyPaymentsTable(props) {
	const {rows}= props
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [showDetails, setShowDetails] = React.useState(false);
	const [activeID, setActiveID] = React.useState(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const toggleDetailsView = (rowId) => {
		setActiveID(rowId);
		setShowDetails(!showDetails);
	};

	const handleNavigateTenantPayments = (occupancy) => {
		dispatch(setOccupancyId(occupancy));
		navigate("/tenants/payments");
  };

	return (
		<>
			<TableContainer sx={{ display: { xs: "none", md: "block" } }} component={Paper}>
				<Table sx={{ minWidth: 700 }} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Property Name</StyledTableCell>
							<StyledTableCell align="center">Amount</StyledTableCell>
							{/* <StyledTableCell align="left">Actions</StyledTableCell> */}
						</TableRow>
					</TableHead>
					<TableBody>
						{props.isLoadingRentals ? (
							<StyledTableRow></StyledTableRow>
						) : (
							<>
								{(rows.length > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row) => (
									<StyledTableRow key={row.id}>
										<StyledTableCell component="th" scope="row">
											{row.property_name}
										</StyledTableCell>
										<StyledTableCell align="center">{currencyFormatter(parseInt(row.total_collected))}</StyledTableCell>
										{/* <StyledTableCell align="left">
											<Button onClick={()=>{handleNavigateTenantPayments(row);}}>
												<h4 style={{ color: "#2094FF", margin: "0 0 8px 0" }}>View Deatails</h4>
											</Button>
										</StyledTableCell> */}
									</StyledTableRow>
								))}
							</>
						)}
					</TableBody>
				</Table>
				<TablePagination
					component="div"
					colSpan={12}
					count={rows.length}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Rows per page" // Custom label for "Rows per page"
					sx={{
						// Styles for the TablePagination component itself
						"& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input.css-baf1rs-MuiInputBase-root-MuiTablePagination-select": {
							marginRight: "5px",
						},
						"& .MuiTablePagination-actions": {
							marginLeft: 0,
						},
						"& .MuiTablePagination-displayedRows": {
							fontSize: "1rem",
							margin: 0,
						},
						"& .MuiSelect-select.MuiInputBase-input": {
							fontSize: "1rem",
							marginTop: "10px",
						},
						"& .MuiTablePagination-selectLabel": {
							fontSize: "1rem",
						},
						"& .MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select": {
							marginTop: "4px",
						},
					}}
				/>
			</TableContainer>

			{/* MOBILE */}
			<TableContainer sx={{ display: { xs: "block", md: "none" } }} component={Paper}>
				<Table aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Property Name</StyledTableCell>
							<StyledTableCell align="left">Actions</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.isLoadingRentals ? (
							<StyledTableRow></StyledTableRow>
						) : (
							<>
								{(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row) => (
									<StyledTableRow key={row.id}>
										<StyledTableCell align="left">
											<span>
												{row.property_name}
											</span>
											<Stack spacing={1} style={{ fontSize: "16px", marginTop: "7px" }}>
												{activeID === row.id && showDetails ? (
													<>
														<span>
															<strong>Amount:</strong> {currencyFormatter(parseInt(row.total_collected))}
														</span>
													</>
												) : null}
											</Stack>
										</StyledTableCell>
										<StyledTableCell align="center">
											<span style={{ cursor: "pointer", display: "flex", alignItems: "center", fontSize: "15px" }} onClick={() => toggleDetailsView(row.id)}>
												See{" "}
												{activeID === row.id && showDetails ? (
													<>
														{" "}
														less <IoMdEyeOff style={{ marginLeft: "5px", marginTop: "1px" }} />{" "}
													</>
												) : (
													<>
														{" "}
														more <FaRegEye style={{ marginLeft: "5px", marginTop: "1px" }} />{" "}
													</>
												)}
											</span>
										</StyledTableCell>
									</StyledTableRow>
								))}
							</>
						)}
					</TableBody>
				</Table>
				<TablePagination
					component="div"
					count={rows.length}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Rows" // Custom label for "Rows per page"
					sx={{
						// Styles for the TablePagination component itself
						"& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input.css-baf1rs-MuiInputBase-root-MuiTablePagination-select": {
							marginRight: "5px",
						},
						"& .MuiTablePagination-actions": {
							marginLeft: 0,
						},
						"& .MuiTablePagination-displayedRows": {
							fontSize: "0.95rem",
							margin: 0,
						},
						"& .MuiSelect-select.MuiInputBase-input": {
							fontSize: "0.95rem",
							marginTop: "5px",
						},
						"& .MuiTablePagination-selectLabel": {
							fontSize: "0.95rem",
						},
						"& .MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select": {
							marginTop: "4px",
						},
					}}
				/>
			</TableContainer>
		</>
	);
}
