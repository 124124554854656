

import { Document, Text, StyleSheet, View, Page, Font, } from "@react-pdf/renderer";
import { PdfHeading, PdfRegular, PdfMedium, PdfPageNumber, PdfSmall, PdfSubSubHeading, PdfSubSubSubHeading } from "components/pdf/typographyComponents";
import { PdfHeaderView, PdfSubHeaderView, PdfView, PdfBorderView, PdfFooterView, PdfGrid } from "components/pdf/wrappedViewsComponents";
import moment from "moment";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";

Font.register({
  family: "arimaRegular",
  src: "../../../../components/pdf/Arima/static/Arima-Regular.ttf",
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    paddingBottom: "80pt",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    borderColor: "#000",
    borderWidth: 1,
    marginHorizontal: 20,
    flexFlow: 1,
  },
  tableRow: {
    flexDirection: "row",
  },
  headerBg: {
    backgroundColor: "#108ED4",
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
  },
  tableCellHeader: {
    margin: 2,
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
  },
  tableCell: {
    margin: 2,
    fontSize: 12,
  },
  textCenter: {
    textAlign: "center",
  },
});



const InvoicePdf = ({viewData}) => {


  const invoice_number = viewData.id;
  const grand_total = viewData.invoice_amount;
  const invoice_date = viewData.date_created;
  const invoice_to = viewData.related_occupancy.related_tenant;
  const invoice_item = viewData.invoice_description;
  const invoice_to_location = viewData.related_occupancy.related_rental_unit.related_rental
  const payment_terms = viewData.payment_terms;
  const vat_percentage = viewData.vat_percentage;
  const vat_amount = viewData.vat_amount;

  const fields = [
    
    {
      title: "Quantity",
      width: "12",
    },
    {
      title: "Description",
      width: "48",
    },
    {
      title: "Unit Price",
      width: "20",
    },
    {
      title: "Amount",
      width: "20",
    },
  ];

  const PdfTable = ({ headerFixed = false, fields, style }) => (
  <View style={styles.table}>
    <View style={[styles.tableRow, styles.headerBg]}>
      {fields.map((item, index) => (
        <View key={index} style={[{ width: item.width + "%" }]}>
          <Text style={[styles.tableCellHeader]}>{item?.title}</Text>
        </View>
      ))}
    </View>
    {[{}]?.map((row, index) => (
      <View key={index} style={styles.tableRow}>
        
          <PdfBorderView key={0} style={{   width: fields[0]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{1}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[1]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{invoice_item}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[2]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{grand_total}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[3]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{grand_total}</PdfSmall>
          </PdfBorderView>
          
        
      </View>
    ))}
    <View style={styles.tableRow}>
        
          
          <PdfBorderView key={0} style={{   width: fields[0]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[1]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[2]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <Text style={[styles.tableCellHeader]}>TOTAL</Text>
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[3]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <Text style={[styles.tableCellHeader]} >{currencyFormatter(parseFloat(grand_total))}</Text>
          </PdfBorderView>
          
        
      </View>
  </View>
); 



  return (
    <Document title={`Purchase Invoice #${invoice_number}`}>
      <Page size="A4" style={styles.page}>
        {/* HEADER */}
        <PdfHeaderView fixed></PdfHeaderView>
        <PdfSubHeaderView fixed></PdfSubHeaderView>

        {/* DOCUMENT TITLE */}
        <PdfView flexDirection={"row"} fixed>
          <PdfSubSubHeading>INVOICE</PdfSubSubHeading>
        </PdfView>

        {/* ADDRESSES */}
        {/* <PdfView flexDirection={"row"} style={{ margin: 5 }}>
          <View style={{ flexGrow: 3 }}>
            <PdfSubSubSubHeading>Invoice From</PdfSubSubSubHeading>
            <PdfRegular>
              {invoice_to.first_name + " " + invoice_to.last_name}
            </PdfRegular>
            <PdfRegular>
              Address:{" "}
              {invoice_to_location.location_county +
                ", " +
                invoice_to_location.location}
            </PdfRegular>
            <PdfRegular>Phone: {invoice_to.mobile}</PdfRegular>
          </View>
        </PdfView> */}

        <PdfView flexDirection={"row"} style={{ margin: 5 }}>
          <View style={{ flexGrow: 1 }}>
            <PdfSubSubSubHeading>BILL TO</PdfSubSubSubHeading>
            <PdfRegular>
              {invoice_to.first_name + " " + invoice_to.last_name}
            </PdfRegular>
            <PdfRegular>
              Address:{" "}
              {invoice_to_location.location_county +
                ", " +
                invoice_to_location.location}
            </PdfRegular>
            <PdfRegular>Phone: {invoice_to.mobile}</PdfRegular>
          </View>
          <View style={{ flexGrow: 1, alignItems: "flex-end" }}>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flexGrow: 3, alignItems: "flex-start" }}>
                <PdfSubSubSubHeading>INVOICE# </PdfSubSubSubHeading>
              </View>
              <View style={{ flexGrow: 1, alignItems: "flex-end" }}>
                <PdfRegular>1</PdfRegular>
              </View>
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flexGrow: 3, alignItems: "flex-start" }}>
                <PdfSubSubSubHeading>INVOICE DATE: </PdfSubSubSubHeading>
              </View>
              <View style={{ flexGrow: 1, alignItems: "flex-end" }}>
                <PdfRegular>{moment(invoice_date).format("LL")}</PdfRegular>
              </View>
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flexGrow: 3, alignItems: "flex-start" }}>
                <PdfSubSubSubHeading>DUE DATE: </PdfSubSubSubHeading>
              </View>
              <View style={{ flexGrow: 1, alignItems: "flex-end" }}>
                <PdfRegular>{moment(invoice_date).format("LL")}</PdfRegular>
              </View>
            </View>
          </View>
        </PdfView>

        {/* TABLE */}
        <PdfTable headerFixed fields={fields} />

        <PdfView flexDirection={"row"} fixed>
          <View style={{ flexGrow: 1, alignItems: "flex-end" }}>
            <PdfSmall>
              AMOUNT BEFORE VAT: {currencyFormatter(parseFloat(grand_total))}
            </PdfSmall>
            <PdfSmall>
              VAT AMOUNT({parseFloat(vat_percentage)}%):{" "}
              {currencyFormatter(parseFloat(vat_amount))}
            </PdfSmall>
            <PdfSmall>
              OVERAL AMOUNT:{" "}
              {currencyFormatter(
                parseFloat(grand_total) + parseFloat(vat_amount)
              )}
            </PdfSmall>
          </View>
        </PdfView>

        {/* PAYMENT TERMS */}
        <PdfView flexDirection="row" fixed>
          <View>
            <PdfSmall>PAYMENT TERMS</PdfSmall>
            <PdfSmall>{payment_terms}</PdfSmall>
            <PdfSmall>
              ........................................................................................................................................................................
            </PdfSmall>
          </View>
        </PdfView>

        {/* SIGNATURE */}
        <PdfView flexDirection={"row"} fixed>
          <PdfSmall>
            PREPARED
            BY:...................................................................
            DATE: ...................................
          </PdfSmall>
        </PdfView>

        {/* DOCUMENT DETAILS */}
        {/* <PdfBorderView mv={10}>
          <PdfSubSubSubHeading>Invoice Details</PdfSubSubSubHeading>
          <PdfRegular>{detail}</PdfRegular>
        </PdfBorderView> */}

        {/* PAGE NUMBER */}
        <PdfPageNumber />

        {/* FOOTER */}
        <PdfFooterView fixed>
          <PdfSmall style={{ color: "#F00B6B" }}>Powered By RENTBETA</PdfSmall>
        </PdfFooterView>
      </Page>
    </Document>
  );};


export default InvoicePdf;