import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Divider, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useSelector } from "react-redux";
import moment from 'moment';
import { currencyFormatter, withoutSymbolFormatter } from 'utilities/currencyFormatter';
import axios from 'axios';
import { baseURL } from 'services/API';

// Define the theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FFD700',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const TenantReceipt = () => {
  const receiptRef = useRef();
  const tenantPayment = useSelector((state) => state.tenantPayment);
  const paymentMethods = { 1: "CASH", 2: "MOBILE MONEY", 3: "MOMO PAY" };
  const [itemData, setItemData] = useState([]);

  useEffect(()=>{
    try {
				const response = axios.get(`${baseURL}/tenants/completed_payments/payments_list?period_id=${tenantPayment.related_active_period.id}`)
				setItemData(response.data.data);
			} catch (err) {

			}
  }, [])

  // Function to print the receipt
  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  // Function to download the receipt as a PDF
  const handleDownload = async () => {
    const input = receiptRef.current;
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    pdf.addImage(imgData, 'PNG', 0, 0);
    pdf.save("tenant-receipt.pdf");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', 
               margin:"20px 0 0 0", padding:"0 20 20 80", alignItems:"center" }}>
       
        <Box sx={{ display: 'flex',   width:"210mm"}}>
        
          <Box sx={{ flexGrow: 1, backgroundColor: '#f9f9f9', overflowY: 'auto'}}>
            {/* Receipt Container */}
            <Box ref={receiptRef} sx={{ 
              paddingBottom: 8, 
              backgroundColor: 'white', 
              minHeight: 'calc(100vh - 60px - 80px - 80px)', // Adjust based on Navbar and button height
              overflowY: 'auto',
              marginBottom: 8
            }}>
              {/* Header */}
              <Box sx={{ backgroundColor: 'black', padding: 2, marginTop: 2, marginLeft: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <img src="/assets/nav-image.png" alt="RentBeta Logo" style={{ height: 100 }} />
                <Box sx={{ textAlign: 'right', color: 'gold' }}>
                  <Typography variant="body2" sx={{ color: 'gold', fontWeight: 'bold' }}>
                    ICT Innovation Hub Nakawa, inside UICT
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'gold', fontWeight: 'bold' }}>
                    Plot 19-12 Port Bell Road, Kampala, Uganda
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'gold', fontWeight: 'bold' }}>
                    Tel: +256 703330943 / +256 774453453
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'gold', fontWeight: 'bold' }}>
                    Website: <a href="https://www.rentbeta.africa" style={{ color: 'gold', textDecoration: 'none' }}>www.rentbeta.africa</a>
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'gold', fontWeight: 'bold' }}>
                    Email: admin@rentbeta.africa
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ marginTop: 5, marginX:"5px" }}>
                <Typography variant="h5" sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Rent Payment Receipt</Typography>
                <Divider sx={{ backgroundColor: 'black' }} />
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 2 }}>
                  <Box sx={{ flex: 1, padding: 1 }}>
                    <Typography variant="body1" sx={{ marginBottom: 1 }}><strong>Tenant Name:</strong> {tenantPayment.related_occupancy.related_tenant.first_name} {tenantPayment.related_occupancy.related_tenant.last_name}</Typography>
                    <Typography variant="body1" sx={{ marginBottom: 1 }}><strong>Date:</strong>{tenantPayment.related_active_period.date_started}</Typography>
                  </Box>
                  <Box sx={{ flex: 1, padding: 1 }}>
                    <Typography variant="body1" sx={{ marginBottom: 1 }}><strong>Telephone Number:</strong> {tenantPayment.related_occupancy.related_tenant.phone_number}</Typography>
                    <Typography variant="body1" sx={{ marginBottom: 1 }}><strong>Rental Property:</strong>{tenantPayment.related_occupancy.related_rental_unit.unit_name} {tenantPayment.related_occupancy.related_rental_unit.unit_type}</Typography>
                  </Box>
                </Box>
                
                <Divider sx={{ backgroundColor: 'black' }} />

                <Typography variant="h6" sx={{ color: 'black', margin: 2,  }}>Payment Period</Typography>

                <Box sx={{ backgroundColor: 'black', color: 'white', padding: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 1, marginX:"5px"  }}>
                  <Typography variant="body1" sx={{ flex: 2, fontWeight: 'bold' }}>
                    Description
                  </Typography>
                  <Typography variant="body1" sx={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
                    Amount
                  </Typography>
                </Box>
              </Box>
              
              {/* Receipt Items */}
              <Box sx={{ backgroundColor: 'white', margin: 0, padding:2, }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body1" sx={{ flex: 2 }}>
                    Rent for {tenantPayment.related_active_period.related_month.month} {tenantPayment.related_active_period.related_month.year} startng {moment(tenantPayment.related_active_period.date_started).format('L')} to {moment(tenantPayment.related_active_period.date_ended).format('L')} 
                  </Typography>
                  <Typography variant="body1" sx={{ flex: 1, textAlign: 'center' }}>
                    {tenantPayment.period_rent}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body1" sx={{ flex: 2 }}>
                    Outstanding Balance
                  </Typography>
                  <Typography variant="body1" sx={{ flex: 1, textAlign: 'center' }}>
                    {tenantPayment.outstanding_balance}
                  </Typography>
                </Box>
                <Divider/>
              </Box>
              
             

              {/* Receipt Details */}
              <Box sx={{ backgroundColor: 'white', marginTop: "1px", padding: 2,  marginX:"2px"  }}>
                <Typography variant="h6" sx={{ color: 'black', margin: 1,  }}>Payments Made</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 2 }}>
                   <Table stickyHeader aria-label="sticky table" >
                    <TableHead>
                      <TableRow>
                      <TableCell style={{ minWidth: 50,padding: "6px 20px" }}>Payment Date</TableCell>
                      <TableCell style={{ minWidth: 150,padding: "6px 20px", textAlign: "center"  }}>Amount Paid</TableCell>
                      <TableCell style={{ minWidth: 50,padding: "6px 20px", textAlign: "center"  }}>Mode of Payment</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itemData.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={4} style={{ textAlign: "center" }}>
                          <Typography variant="h5">No payments recorded</Typography>
                        </TableCell>
                      </TableRow>
                      ) : (
                      itemData
                        .map((row, index) => {
                        return (
                          <TableRow key={row.id}>
                          <TableCell style={{ padding: "6px 20px", textAlign: "center"  }}>{moment(row.date_paid).format('LL')}</TableCell>
                          <TableCell style={{ padding: "6px 20px", textAlign: "center" }}>{withoutSymbolFormatter(parseFloat(row.amount))}</TableCell>
                          <TableCell style={{ padding: "6px 20px", textAlign: "center"  }}>{paymentMethods[row.payment_method]}</TableCell>
                          </TableRow>
                        );
                        })
                      )}
                    </TableBody>
                  </Table> 
                </Box>
              </Box>


              <Box sx={{ backgroundColor: 'white', marginTop: "1px", padding: 2,  marginX:"5px"  }}>
                <Typography variant="h6" sx={{ color: 'black', marginX: 2,  }}>Summary</Typography>
                <Box sx={{ display: 'flex', padding: 1 }}>
                  <Typography variant="body1" fontWeight="bold" sx={{ flex: 1 }}>TOTAL PAYMENTS MADE:</Typography>
                  <Typography variant="body1" sx={{ flex: 1, textAlign: 'center' }}>{currencyFormatter(parseFloat(tenantPayment.total_amout_paid))}</Typography>
                </Box>
                <Box sx={{ display: 'flex', padding: 1 }}>
                  <Typography variant="body1" fontWeight="bold" sx={{ flex: 1 }}>REMAINING BALANCE:</Typography>
                  <Typography variant="body1" sx={{ flex: 1, textAlign: 'center' }}>{currencyFormatter(parseFloat(tenantPayment.balance_carried))}</Typography>
                </Box>
              </Box>
              <Divider/>

              {/* Footer */}
              <Box sx={{ textAlign: 'center', marginTop: 4 }}>
                <Typography variant="body1" sx={{ color: 'black', fontWeight: 'bold' }}>
                  Powered by RentBeta.africa
                </Typography>
                <Typography variant="body3" sx={{ color: 'black'}}>
                  This is an autogenerated receipt. Do not sign
                </Typography>
              </Box>
            </Box>

            {/* Buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'center', paddingY: 2, paddingTop:0 }}>
              <Button variant="contained" color="primary" onClick={handlePrint} sx={{ marginRight: 2 }}>
                Print Receipt
              </Button>
              <Button variant="contained" color="secondary" onClick={handleDownload}>
                Download PDF
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default TenantReceipt;
