import * as React from "react";
import { Grid, Box } from "@mui/material";
import TicketsTable from "components/Tables/TicketsTable";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import BasicSelect from "components/select";
import { useGetRentalTicketsByProperty, useRentals } from "services/hooks/rentals";
import TicketPopover from "components/PopOvers/TicketPopover";
import { formatDate } from "utilities/date";
import ProgressScale from "components/ProgressScale";
import DummyTable from "components/Tables/DummyTable";
import FormDialog from "components/modal";
import TicketView from "components/ModalContent/ViewDetails/TicketView";
import TicketDelete from "components/ModalContent/DeleteItem/TicketDelete";
import TicketUpdateForm from "components/Forms/TicketUpdateForm";

export default function Tickets() {
	const [rental, setRental] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const [details, setDetails] = React.useState("");
	const [modal, setModal] = React.useState("");
	const { rentalTicketsByProperty, isLoadingRentalTicketsByProperty } = useGetRentalTicketsByProperty();
	const { rentals } = useRentals();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;

	const handleSelectChange = (event) => {
		setRental(event.target.value);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleModalView = (modalView) => {
		setModal(modalView);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleClickPopOver = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClosePopOver = () => {
		setAnchorEl(null);
	};


	return (
		<Box m="20px">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{isLoadingRentalTicketsByProperty && <ProgressScale />}
					<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<h1 className="pageHeader">Tickets</h1>
						{/* <Button
										sx={{ display: { xs: "flex", sm: "none" }, marginLeft: "8%" }}
										className="add-button"
										variant="contained"
										onClick={handleClickOpen}
										endIcon={<AddIcon />}
									>
										Add Ticket
									</Button> */}
					</div>
					<Grid container spacing={2}>
						<Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
							<BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter Rental"} data={rentals} />
						</Grid>
						<Grid item xs={12} sm={5} md={8} sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "end", alignItems: "center" }}>
							{/* <Button className="add-button" variant="contained" onClick={handleClickOpen} endIcon={<AddIcon />}>
											Add Ticket
										</Button> */}
						</Grid>
						<Grid item sx={{ display: { xs: "block", sm: "none" } }} xs={12} sm={4}>
							<BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter Rental"} data={rentals} />
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sx={{ marginTop: 3 }}>
							{isLoadingRentalTicketsByProperty ? (
								<DummyTable />
							) : (
								<TicketsTable
									rows={rentalTicketsByProperty}
									isLoadingRentalTickets={isLoadingRentalTicketsByProperty}
									handleClickPopOver={handleClickPopOver}
									id={popperId}
									formatDate={formatDate}
									setDetails={setDetails}
								/>
							)}
						</Grid>
					</Grid>
					<TicketPopover
						popperId={popperId}
						openPopper={openPopper}
						anchorEl={anchorEl}
						handleClosePopOver={handleClosePopOver}
						handleClickOpen={handleClickOpen}
						details={details}
						handleModalView={handleModalView}
					/>
					{modal === "view" ? (
						<FormDialog title={details.title} enableActions={false} content={<TicketView details={details} />} open={open} handleClose={handleClose} />
					) : modal === "edit" ? (
						<FormDialog
							title="Update Ticket Detail"
							enableActions={true}
							open={open}
							content={<TicketUpdateForm details={details} />}
							handleClose={handleClose}
							// handleSubmit={console.log(2) }
							buttonText="update"
						/>
					) : modal === "delete" ? (
						<FormDialog
							title="Delete Ticket"
							enableActions={true}
							open={open}
							content={<TicketDelete details={details} />}
							handleClose={handleClose}
							buttonText="delete"
						/>
					) : null}
				</Grid>
			</Grid>
		</Box>
	);
}
