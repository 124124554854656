/* eslint-disable no-unused-vars */
import * as React from "react";
import axios from "axios";
import { baseURL } from "services/API";
import { Grid, Box } from "@mui/material";
import RentalsTable from "../../components/Tables/RentalsTable";
import DummyTable from "components/Tables/DummyTable";
import Button from "@mui/material/Button";
import FormDialog from "components/modal";
import BasicSelect from "components/select";
import AddIcon from "@mui/icons-material/Add";
import { useRentals } from "services/hooks/rentals";
import RentalsForm from "components/Forms/RentalsForm";
import PropertyPopover from "components/PopOvers/PropertyPopover";
import ProgressScale from "components/ProgressScale";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setRefreshCount } from "state";
import TopSnack from "components/TopSnack";
import { setPropertyId } from "state";
import PropertyUpdateForm from "components/Forms/PropertyUpdateForm";
import * as yup from "yup";

const Rentals = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const landlordId = useSelector((state) => state.landlord);
	const { rentals, isLoadingRentals } = useRentals();
	const [rental, setRental] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const [modal, setModal] = React.useState("");
	const [details, setDetails] = React.useState("");
	const [rentalName, setRentalName] = React.useState("");
	const [rentalType, setRentalType] = React.useState("");
	const [rentalLocation, setRentalLocation] = React.useState("");
	const [rentalCity, setRentalCity] = React.useState("");
	const [security, setSecurity] = React.useState(false);
	const [gym, setGym] = React.useState(false);
	const [hospital, setHospital] = React.useState(false);
	const [mainRoad, setMainRoad] = React.useState(false);
	const [elevator, setElevator] = React.useState(false);
  	const [swimmingPool, setSwimmingPool] = React.useState(false);
	const [activeRental, setActiveRental] = React.useState("");
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;
	const [deleteMessage, setDeleteMessage] = React.useState("");
  	const [deleteButton, setDeleteButton] = React.useState(false);
	const [openSnack, setOpenSnack] = React.useState({
		open: false,
		vertical: "top",
		horizontal: "center",
	});
	const [severity, setSeverity] = React.useState("");
	const [message, setMessage] = React.useState("");

	const handleNavigateUnits = (property_id) => {
		dispatch(setPropertyId(property_id))
		navigate("/propertyDetailsTab");
	};

	const handleRentalNameChange = (event) => {
		const {value} = event.target
		setRentalName(value);
		if (!value.trim()) {
      		setErrors((prev) => ({ ...prev, 'rental_name': `Property Name is required`}));
		} else {
			setErrors((prev) => ({ ...prev, "rental_name": "" }));
		}
	};

	const handleChangeRentalType = (event) => {
		const { value } = event.target;
		setRentalType(value);
		if (!value.trim()) {
      		setErrors((prev) => ({ ...prev, 'rental_type': `Select the property type`}));
		} else {
			setErrors((prev) => ({ ...prev, "rental_type": "" }));
		}
	};

	const handleRentalLocationChange = (event) => {
		const { value } = event.target;
		setRentalLocation(value);
		if (!value.trim()) {
      	  		setErrors((prev) => ({ ...prev, 'location_county': `Input a parish or county in the selected City`}));
		} else {
			setErrors((prev) => ({ ...prev, "location_county": "" }));
		}
	};


  const handleRentalCityChange = (event) => {
    const { value } = event.target;
		setRentalCity(value);
		if (!value.trim()) {
      		setErrors((prev) => ({ ...prev, 'location': `Input a City`}));
		} else {
			setErrors((prev) => ({ ...prev, "location": "" }));
		}
  };

	const handleSecurityCheck = (event) => {
		setSecurity(event.target.checked);
	};

	const handleSwimmingPoolCheck = (event) => {
		setSwimmingPool(event.target.checked);
	};

	const handleHospitalCheck = (event) => {
		setHospital(event.target.checked);
	};

	const handleMainRoadCheck = (event) => {
		setMainRoad(event.target.checked);
	};

	const handleElevatorCheck = (event) => {
		setElevator(event.target.checked);
	};

	const handleGymCheck = (event) => {
		setGym(event.target.checked);
	};

	const handleClickOpenCreate = () => {
		handleModalView('create');
		handleClickOpen()
	};


	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setModal("");
		setErrors({})
	};

	const handleClickPopOver = (event, row) => {
		setAnchorEl(event.currentTarget);
		setActiveRental(row);
	};

	const handleClosePopOver = () => {
		setAnchorEl(null);
	};

	const handleCloseSnack = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnack({ ...openSnack, open: false });
	};

	const handleModalView = (modalView) => {
		setModal(modalView);
	};

	const handleSubmitDelete = async () => {
    try {
      const response = await axios.delete(
        `${baseURL}/landlords/rentals/delete?rental_id=${activeRental}`
      );

      setOpenSnack({ ...openSnack, open: true });
      setSeverity("success");
      setMessage("Property Deleted Successfully");
      setOpen(false);
      dispatch(setRefreshCount());
    } catch (err) {
      setOpenSnack({ ...openSnack, open: true });
      setSeverity("error");
      setMessage("Property Delete failed, Please check unit details");
    }
  };

	const [errors, setErrors] = React.useState({});
	const propertySchema = yup.object().shape({
		rental_name: yup.string().required("Please enter the property name"),
		location: yup.string().required("Input a City"),
    	location_county: yup.string().required("Input a parish or county in the selected City"),
		rental_type: yup.string().required("Select the property type"),
	});

	const handleRentalFormSubmit = async () => {

		try {
			await propertySchema.validate(
				{
					rental_name: rentalName,
					rental_type: rentalType,
					location: rentalCity,
					location_county: rentalLocation,
					has24_security: security,
					has_gym: gym,
					has_swimming_pool: swimmingPool,
					has_elevator: elevator,
					hospital_access: hospital,
					main_road_access: mainRoad,
				}, {abortEarly:false});
		} catch (error) {
			const newError = {}
			error.inner.forEach(err => {
				newError[err.path]=err.message
			})
			setErrors(newError)
			return
		}

		try {
			await axios.post(`${baseURL}/landlords/rentals`, {
				related_landlord: landlordId,
				rental_name: rentalName,
				rental_type: rentalType,
				location: rentalCity,
        		location_county: rentalLocation,
				has24_security: security,
				has_gym: gym,
				has_swimming_pool: swimmingPool,
				has_elevator: elevator,
				hospital_access: hospital,
				main_road_access: mainRoad,
			});
			// setSuccess(true);
			// setLoading(false);
			setOpenSnack({ ...openSnack, open: true});
			setSeverity("success");
			setMessage("Property Added Successfully");
			setOpen(false);
			dispatch(setRefreshCount());
		} catch (err) {
			setOpenSnack({ ...openSnack, open: true});
			setSeverity("error");
			setMessage("Property Addition failed, Please check property details");
		}
	};


	return (
		<Box m="20px">
			<Grid container spacing={2}>
				<Grid item xs={12} spacing={2}>
					{isLoadingRentals && <ProgressScale />}
					<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<h1 className="pageHeader">Properties</h1>
						<Button
							sx={{ display: { xs: "flex", sm: "none" }, marginLeft: "8%" }}
							className="add-button"
							variant="contained"
							onClick={handleClickOpen}
							endIcon={<AddIcon />}
						>
							Add Property
						</Button>
					</div>

					<Grid container>
						<Grid container spacing={2}>
							<Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
								<BasicSelect value={rental} label={"Filter By Property type"} data={rentals} />
							</Grid>
							<Grid item xs={12} sm={5} md={8} sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "end", alignItems: "center" }}>
								<Button
									sx={{ display: { xs: "none", sm: "flex" } }}
									className="add-button"
									variant="contained"
									onClick={handleClickOpenCreate}
									endIcon={<AddIcon />}
								>
									Add Properties
								</Button>
							</Grid>
							<Grid item sx={{ display: { xs: "block", sm: "none" } }} xs={12} sm={4}>
								<BasicSelect value={rental} label={"Properties"} data={rentals} />
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12} sx={{ marginTop: 3 }}>
								{isLoadingRentals ? (
									<DummyTable />
								) : (
									<RentalsTable
										setDetails={setDetails}
										rows={rentals}
										isLoadingRentals={isLoadingRentals}
										handleClickPopOver={handleClickPopOver}
										id={popperId}
										handleNavigateUnits={handleNavigateUnits}
									/>
								)}
							</Grid>
						</Grid>
						<PropertyPopover
							popperId={popperId}
							openPopper={openPopper}
							anchorEl={anchorEl}
							handleClosePopOver={handleClosePopOver}
							handleClickOpen={handleClickOpen}
							navigate={navigate}
							activeRental={activeRental}
							handleModalView={handleModalView}
							navigateUnits={handleNavigateUnits}
							setDeleteButton={setDeleteButton}
            				setDeleteMessage={setDeleteMessage}
						/>
						
						{modal === 'delete' ? (
							<FormDialog
								title="Delete Property"
								enableActions={deleteButton}
								open={open}
								content={deleteMessage}
								handleClose={handleClose}
								handleSubmit={handleSubmitDelete}
								buttonText="Delete"
							/>
						): modal === 'create' ? (
							<FormDialog
								open={open}
								handleClose={handleClose}
								enableActions={true}
								content={
									<RentalsForm
										rentalType={rentalType}
										rentalCity={rentalCity}
										handleRentalNameChange={handleRentalNameChange}
										handleChangeRentalType={handleChangeRentalType}
										handleRentalLocationChange={handleRentalLocationChange}
										handleRentalCityChange={handleRentalCityChange}
										handleSecurityCheck={handleSecurityCheck}
										handleGymCheck={handleGymCheck}
										handleSwimmingPoolCheck={handleSwimmingPoolCheck}
										handleElevatorCheck={handleElevatorCheck}
										handleHospitalCheck={handleHospitalCheck}
										handleMainRoadCheck={handleMainRoadCheck}
										errors={errors}
										security={security}
										gym={gym}
										swimmingPool={swimmingPool}
										elevator={elevator}
										hospital={hospital}
										mainRoad={mainRoad}
									/>
								}
								title={"Add all Property Details"}
								handleSubmit={handleRentalFormSubmit}
								buttonText="submit"
								buttonClass={"add-button"}
							/>
						):null}


						<TopSnack
							vertical={openSnack.vertical}
							horizontal={openSnack.horizontal}
							open={openSnack.open}
							handleCloseSnack={handleCloseSnack}
							message={message}
							severity={severity}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Rentals;
