import * as React from "react";
import axios from "axios";
import { baseURL } from "../API";
import { useSelector } from "react-redux";

const useRentals = () => {
	const token = useSelector((state) => state.token)
	const landlordId = useSelector((state) => state.landlord)
	const refreshCount = useSelector((state) => state.refreshCount)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [rentals, setRentals] = React.useState([]);
	const [isLoadingRentals, setLoadingRentals] = React.useState(true);
	const [error, setError] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals?landlord_id=${landlordId}`);
				setRentals(response.data.data);
				setLoadingRentals(false);
			} catch (e) {
				setError(true);
				setLoadingRentals(false);
			}
		};
		fetchData();
	}, [landlordId, refreshCount]);
	return { rentals, isLoadingRentals, error };
};

const useRentalUnits = (activeRental) => {
	const token = useSelector((state) => state.token)
	const refreshCount = useSelector((state) => state.refreshCount)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [rentalUnits, setRentalUnits] = React.useState("");
	const [isLoadingRentalUnits, setLoadingRentalUnits] = React.useState(true);
	const [error, setError] = React.useState(false);
	
	const fetchData = async () => {
		try {
			const response = await axios.get(`${baseURL}/landlords/rentals/units?rental_id=${activeRental}`);
			setRentalUnits(response.data.data);
			setLoadingRentalUnits(false);
		} catch (e) {
			setError(true);
			setLoadingRentalUnits(false);
		}
	};

	React.useEffect(() => {
		fetchData();
	}, [activeRental, refreshCount]);
	return { rentalUnits, isLoadingRentalUnits, error, refetch: fetchData };
};

const useUnoccupiedRentalUnits = (activeUnoccupiedRental) => {
	const token = useSelector((state) => state.token)
	const refreshCount = useSelector((state) => state.refreshCount)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [unoccupiedRentalUnits, setUnoccupiedRentalUnits] = React.useState("");
	const [isLoadingUnoccupiedRentalUnits, setLoadingUnoccupiedRentalUnits] = React.useState(true);
	const [error, setError] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals/units/unoccupied?rental_id=${activeUnoccupiedRental}`);
				setUnoccupiedRentalUnits(response.data.data);
				setLoadingUnoccupiedRentalUnits(false);
			} catch (e) {
				setError(true);
				setLoadingUnoccupiedRentalUnits(false);
			}
		};
		fetchData();
	}, [activeUnoccupiedRental, refreshCount]);
	return { unoccupiedRentalUnits, isLoadingUnoccupiedRentalUnits, error };
};

const useHandleRentalFormSubmit = async (rentalName, rentalType, rentalLocation) => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.user.id)
    try {
        await axios.post(`${baseURL}/landlords/rentals`, {
            related_landlord: landlordId,
            rental_name: rentalName,
            rental_type:rentalType,
            location: rentalLocation,
        });
        // setSuccess(true);
        // setLoading(false);
    } catch (err) {
        console.log(err)
        // setLoading(false);
        // setError(true);
        // setErrcode(err.message);
    }
}

const useGetRentalTickets = () => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.landlord)
	const [rentalTickets, setRentalTickets] = React.useState([]);
	const [isLoadingRentalTickets, setLoadingRentalTickets] = React.useState(true);
	const [errorLoadingRentalTickets, setErrorLoadingRentalTickets] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals/units/tickets?landlord_id=${landlordId}`);
				setRentalTickets(response.data.data);
				setLoadingRentalTickets(false);
			} catch (e) {
				setErrorLoadingRentalTickets(true);
				setLoadingRentalTickets(false);
			}
		};
		fetchData();
	}, [landlordId]);
	return { rentalTickets, isLoadingRentalTickets, errorLoadingRentalTickets };
};

const useGetRentalTicketsByProperty = () => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const rentalId = useSelector((state) => state.property_id)
	const [rentalTicketsByProperty, setRentalTicketsByProperty] = React.useState([]);
	const [isLoadingRentalTicketsByProperty, setLoadingRentalTicketsByProperty] = React.useState(true);
	const [errorLoadingRentalTicketsByProperty, setErrorLoadingRentalTicketsByProperty] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals/units/tickets?rental_id=${rentalId}`);
				setRentalTicketsByProperty(response.data.data);
				setLoadingRentalTicketsByProperty(false);
			} catch (e) {
				setErrorLoadingRentalTicketsByProperty(true);
				setLoadingRentalTicketsByProperty(false);
			}
		};
		fetchData();
	}, [rentalId]);
	return { rentalTicketsByProperty, isLoadingRentalTicketsByProperty, errorLoadingRentalTicketsByProperty };
};

const useGetRentalTenants = (id) => {
	const token = useSelector((state) => state.token)
	const refreshCount = useSelector((state) => state.refreshCount)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.landlord)
	const [rentalTenants, setRentalTenants] = React.useState([]);
	const [isLoadingRentalTenants, setLoadingRentalTenants] = React.useState(true);
	const [errorLoadingRentalTenants, setErrorLoadingRentalTenants] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals/tenants?landlord_id=${landlordId}`);
				setRentalTenants(response.data.data);
				setLoadingRentalTenants(false);
			} catch (e) {
				setErrorLoadingRentalTenants(true);
				setLoadingRentalTenants(false);
			}
		};
		fetchData();
	}, [landlordId, refreshCount]);
	return { rentalTenants, isLoadingRentalTenants, errorLoadingRentalTenants };
};

const useGetTenantsByRentalProperty = (rental_id) => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const refreshCount = useSelector((state) => state.refreshCount)
	const [tenantsByProperty, setTenantsByProperty] = React.useState([]);
	const [isLoadingTenantsByProperty, setLoadingTenantsByProperty] = React.useState(true);
	const [errorLoadingTenantsByProperty, setErrorLoadingTenantsByProperty] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals/tenants?rental_id=${rental_id}`);
				setTenantsByProperty(response.data.data);
				setLoadingTenantsByProperty(false);
			} catch (e) {
				setErrorLoadingTenantsByProperty(true);
				setLoadingTenantsByProperty(false);
			}
		};
		fetchData();
	}, [token, refreshCount]);

	return { tenantsByProperty, isLoadingTenantsByProperty, errorLoadingTenantsByProperty };
};


const useGetPastTenantsByRentalProperty = (rental_id) => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const refreshCount = useSelector((state) => state.refreshCount)
	const [pastTenantsByProperty, setPastTenantsByProperty] = React.useState([]);
	const [isLoadingPastTenantsByProperty, setLoadingPastTenantsByProperty] = React.useState(true);
	const [errorLoadingPastTenantsByProperty, setErrorLoadingPastTenantsByProperty] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals/tenants?rental_id=${rental_id}&occupancy_status=False`);
				setPastTenantsByProperty(response.data.data);
				setLoadingPastTenantsByProperty(false);
			} catch (e) {
				setErrorLoadingPastTenantsByProperty(true);
				setLoadingPastTenantsByProperty(false);
			}
		};
		fetchData();
	}, [token, refreshCount]);

	return { pastTenantsByProperty, isLoadingPastTenantsByProperty, errorLoadingPastTenantsByProperty };
};


const useGetDashboardData = () => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.landlord)
	const [dashboardData, setDashbaordData] = React.useState({});
	const [isLoadingDashboardData, setLoadingDashboardData] = React.useState(true);
	const [errorLoadingDashboardData, setErrorLoadingDashboardData] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/dashboard?landlord_id=${landlordId}`);
				setDashbaordData(response.data.data);
				setLoadingDashboardData(false);
			} catch (e) {
				setErrorLoadingDashboardData(true);
				setLoadingDashboardData(false);
			}
		};
		fetchData();
	}, [landlordId]);
	return { dashboardData, isLoadingDashboardData, errorLoadingDashboardData };
};

const useGetDashboardPaymentsData = () => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.landlord)
	const [dashboardPaymentsData, setDashbaordPaymentsData] = React.useState([]);
	const [isLoadingDashboardPaymentsData, setLoadingDashboardPaymentsData] = React.useState(true);
	const [errorLoadingDashboardPaymentsData, setErrorLoadingDashboardPaymentsData] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/properties/payments?landlord_id=${landlordId}`);
				setDashbaordPaymentsData(response.data.data);
				setLoadingDashboardPaymentsData(false);
			} catch (e) {
				setErrorLoadingDashboardPaymentsData(true);
				setLoadingDashboardPaymentsData(false);
			}
		};
		fetchData();
	}, [landlordId]);
	return { dashboardPaymentsData, isLoadingDashboardPaymentsData, errorLoadingDashboardPaymentsData };
};

const useGetPaymentsData = () => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.landlord)
	const [paymentsData, setPaymentsData] = React.useState([]);
	const [isLoadingPaymentsData, setLoadingPaymentsData] = React.useState(true);
	const [errorLoadingPaymentsData, setErrorLoadingPaymentsData] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals/units/payments?landlord_id=${landlordId}`);
				setPaymentsData(response.data.data);
				setLoadingPaymentsData(false);
			} catch (e) {
				setErrorLoadingPaymentsData(true);
				setLoadingPaymentsData(false);
			}
		};
		fetchData();
	}, [landlordId]);
	return { paymentsData, isLoadingPaymentsData, errorLoadingPaymentsData };
};

const useGetPaymentsByActivePeriodData = (period_id) => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [paymentsByActivePeriodData, setPaymentsByActivePeriodData] = React.useState([]);
	const [isLoadingPaymentsByActivePeriodData, setLoadingPaymentsByActivePeriodData] = React.useState(true);
	const [errorLoadingPaymentsByActivePeriodData, setErrorLoadingPaymentsByActivePeriodData] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/tenants/completed_payments/payments_list?period_id=${period_id}`);
				setPaymentsByActivePeriodData(response.data.data);
				setLoadingPaymentsByActivePeriodData(false);
			} catch (e) {
				setErrorLoadingPaymentsByActivePeriodData(true);
				setLoadingPaymentsByActivePeriodData(false);
			}
		};
		fetchData();
	}, [period_id]);
	return { paymentsByActivePeriodData, isLoadingPaymentsByActivePeriodData, errorLoadingPaymentsByActivePeriodData };
};

const useGetLandlordPaymentsData = () => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.landlord)
	const [landlordPaymentsData, setLandlordPaymentsData] = React.useState([]);
	const [isLoadingLandlordPaymentsData, setLoadingLandlordPaymentsData] = React.useState(true);
	const [errorLoadingLandlordPaymentsData, setErrorLoadingLandlordPaymentsData] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals/landlord_payments?landlord_id=${landlordId}`);
				setLandlordPaymentsData(response.data.data);
				setLoadingLandlordPaymentsData(false);
			} catch (e) {
				setErrorLoadingLandlordPaymentsData(true);
				setLoadingLandlordPaymentsData(false);
			}
		};
		fetchData();
	}, [landlordId]);
	return { landlordPaymentsData, isLoadingLandlordPaymentsData, errorLoadingLandlordPaymentsData };
};

const useGetLandlordInvoicesData = () => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.landlord)
	const [invoicesData, setInvoicesData] = React.useState([]);
	const [isLoadingInvoicesData, setLoadingInvoicesData] = React.useState(true);
	const [errorLoadingInvoicesData, setErrorLoadingInvoicesData] = React.useState(false);
	
	const fetchData = async () => {
		try {
			const response = await axios.get(`${baseURL}/landlords/rentals/invoices?landlord_id=${landlordId}`);
			setInvoicesData(response.data.data);
			setLoadingInvoicesData(false);
		} catch (e) {
			setErrorLoadingInvoicesData(true);
			setLoadingInvoicesData(false);
		}
	};
	React.useEffect(() => {
		fetchData();
	}, [landlordId]);
	return { invoicesData, isLoadingInvoicesData, errorLoadingInvoicesData, refetch: fetchData };
};


const useGetLandlordExpenseData = () => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.landlord)
	const [expenseData, setExpenseData] = React.useState([]);
	const [isLoadingExpenseData, setLoadingExpenseData] = React.useState(true);
	const [errorLoadingExpenseData, setErrorLoadingExpenseData] = React.useState(false);
	
	const fetchData = async () => {
		try {
			const response = await axios.get(`${baseURL}/admin/landlord_expenses?landlord_id=${landlordId}`);
			setExpenseData(response.data.data);
			setLoadingExpenseData(false);
		} catch (e) {
			setErrorLoadingExpenseData(true);
			setLoadingExpenseData(false);
		}
	};
	React.useEffect(() => {
		fetchData();
	}, [landlordId]);
	return { expenseData, isLoadingExpenseData, errorLoadingExpenseData, refetch: fetchData };
};



const useGetMonthsData = () => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [monthsData, setMonthsData] = React.useState([]);
	const [isLoadingMonthsData, setLoadingMonthsData] = React.useState(true);
	const [errorLoadingMonthsData, setErrorLoadingMonthsData] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/admin/months`);
				setMonthsData(response.data.data);
				setLoadingMonthsData(false);
			} catch (e) {
				setErrorLoadingMonthsData(true);
				setLoadingMonthsData(false);
			}
		};
		fetchData();
	}, []);
	return { monthsData, isLoadingMonthsData, errorLoadingMonthsData };
};


const useGetTenantOccupancyActivePeriodsData = () => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const occupancyId = useSelector((state) => state.occupancy.id)
	const [activePeriods, setActivePeriods] = React.useState([]);
	const [isLoadingActivePeriods, setLoadingActivePeriods] = React.useState(true);
	const [errorLoadingActivePeriods, setErrorLoadingActivePeriods] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/tenants/completed_payments?occupancy_id=${occupancyId}`);
				setActivePeriods(response.data.data);
				setLoadingActivePeriods(false);
			} catch (e) {
				setErrorLoadingActivePeriods(true);
				setLoadingActivePeriods(false);
			}
		};
		fetchData();
	}, [occupancyId]);
	return { activePeriods, isLoadingActivePeriods, errorLoadingActivePeriods };
};

const useExpenseTypes = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [expenseTypes, setExpenseTypes] = React.useState([]);
	const [isLoadingExpenseTypes, setLoadingExpenseTypes] = React.useState(true);
	const [errorExpenseTypes, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/admin/expense_types`);
				setExpenseTypes(response.data.data);
				setLoadingExpenseTypes(false);
			} catch (e) {
				setError(true);
				setLoadingExpenseTypes(false);
			}
		};
		fetchData();
	}, []);

	return { expenseTypes, isLoadingExpenseTypes, errorExpenseTypes };
};

const useExpenseDetails = (expenseType) => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [expenseDetails, setExpenseDetails] = React.useState([]);
	const [isLoadingExpenseDetails, setLoadingExpenseDetails] = React.useState(true);
	const [errorExpenseDetails, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/admin/expense_details?type_id=${expenseType}`);
				setExpenseDetails(response.data.data);
				setLoadingExpenseDetails(false);
			} catch (e) {
				setError(true);
				setLoadingExpenseDetails(false);
			}
		};
		fetchData();
	}, [expenseType]);

	return { expenseDetails, isLoadingExpenseDetails, errorExpenseDetails };
};

const usePropertyDashboardStatistics = (property) =>{
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [propertyDashboardStatistics, setPropertyDashboardStatistics] = React.useState({"collection_rate": {"paid": 0,"unpaid": 0},"occupancy_rate": {"occupied": 0,"unoccupied": 0}});
	const [isLoadingPropertyDashboardStatistics, setLoadingPropertyDashboardStatistics] = React.useState(true);
	const [errorPropertyDashboardStatistics, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/properties/statistics?rental_id=${property}`);
				setPropertyDashboardStatistics(response.data.data);
				setLoadingPropertyDashboardStatistics(false);
			} catch (e) {
				setError(true);
				setLoadingPropertyDashboardStatistics(false);
			}
		};
		fetchData();
	}, [property]);

	return { propertyDashboardStatistics, isLoadingPropertyDashboardStatistics, errorPropertyDashboardStatistics };
}

const usePropertyDashboardRevenue = (property, type) =>{
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [propertyDashboardRevenue, setPropertyDashboardRevenue] = React.useState({
        "balances": {"total_balance": 0,"total_income": 0,"total_expense": 0},
        "months": [
            {"month": "January","alias": "Jan","income": 0,"expense": 0},{"month": "February","alias": "Feb","income": 0,"expense": 0},{"month": "March","alias": "Mar","income": 0,"expense": 0},{"month": "April","alias": "Apr","income": 0,"expense": 0},{"month": "May","alias": "May","income": 0,"expense": 0},{"month": "June","alias": "Jun","income": 0,"expense": 0},{"month": "July","alias": "Jul","income": 0,"expense": 0},{"month": "August","alias": "Aug","income": 0,"expense": 0},{"month": "September","alias": "Sep","income": 0,"expense": 0},{"month": "October","alias": "Oct","income": 0,"expense": 50000},{"month": "November","alias": "Nov","income": 0,"expense": 0},{"month": "December","alias": "Dec","income": 0,"expense": 0}
        ]
    });
	const [isLoadingPropertyDashboardRevenue, setLoadingPropertyDashboardRevenue] = React.useState(true);
	const [errorPropertyDashboardRevenue, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/properties/revenue_overview?${type}=${property}`);
				setPropertyDashboardRevenue(response.data.data);
				setLoadingPropertyDashboardRevenue(false);
			} catch (e) {
				setError(true);
				setLoadingPropertyDashboardRevenue(false);
			}
		};
		fetchData();
	}, [property]);

	return { propertyDashboardRevenue, isLoadingPropertyDashboardRevenue, errorPropertyDashboardRevenue };
};

const usePropertyDashboardRevenueMonths = (property, type) =>{
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [propertyDashboardRevenueMonths, setPropertyDashboardRevenueMonths] = React.useState({
        "months": [
            {"month": "January","alias": "Jan","income": 0,"expense": 0},{"month": "February","alias": "Feb","income": 0,"expense": 0},{"month": "March","alias": "Mar","income": 0,"expense": 0},{"month": "April","alias": "Apr","income": 0,"expense": 0},{"month": "May","alias": "May","income": 0,"expense": 0},{"month": "June","alias": "Jun","income": 0,"expense": 0},{"month": "July","alias": "Jul","income": 0,"expense": 0},{"month": "August","alias": "Aug","income": 0,"expense": 0},{"month": "September","alias": "Sep","income": 0,"expense": 0},{"month": "October","alias": "Oct","income": 0,"expense": 50000},{"month": "November","alias": "Nov","income": 0,"expense": 0},{"month": "December","alias": "Dec","income": 0,"expense": 0}
        ]
    });
	const [isLoadingPropertyDashboardRevenueMonths, setLoadingPropertyDashboardRevenueMonths] = React.useState(true);
	const [errorPropertyDashboardRevenueMonths, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/properties/monthly_income?${type}=${property}`);
				setPropertyDashboardRevenueMonths(response.data.data);
				setLoadingPropertyDashboardRevenueMonths(false);
			} catch (e) {
				setError(true);
				setLoadingPropertyDashboardRevenueMonths(false);
			}
		};
		fetchData();
	}, [property]);

	return { propertyDashboardRevenueMonths, isLoadingPropertyDashboardRevenueMonths, errorPropertyDashboardRevenueMonths };
};


const usePropertyDashboardCards = (property) =>{
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [propertyDashboardCards, setPropertyDashboardCards] = React.useState({
        "cards": {
            "total_units": {
                "number": 0
            },
            "open_tickets": {
                "number": 0
            },
            "current_tenants": {
                "number": 0,
                "units": 0
            },
            "rent_arrears": {
                "amount": 0,
                "units": 0,
                "as_of": "December"
            },
            "rent_collected": {
                "amount": 0,
                "units": 0,
                "total": 0
            }
        }
    });
	const [isLoadingPropertyDashboardCards, setLoadingPropertyDashboardCards] = React.useState(true);
	const [errorPropertyDashboardCards, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/properties/dashboard_cards?rental_id=${property}`);
				setPropertyDashboardCards(response.data.data);
				setLoadingPropertyDashboardCards(false);
			} catch (e) {
				setError(true);
				setLoadingPropertyDashboardCards(false);
			}
		};
		fetchData();
	}, [property]);

	return { propertyDashboardCards, isLoadingPropertyDashboardCards, errorPropertyDashboardCards };
}

const useGetRentalTax = (id)=>{
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [rentalTaxDetails, setRentalTaxDetails] = React.useState([]);
	const [isLoadingRentalTaxDetails, setLoadingRentalTaxDetails] = React.useState(true);
	const [errorRentalTaxDetails, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/properties/rental_tax?rental_id=${id}`);
				setRentalTaxDetails(response.data.data);
				setLoadingRentalTaxDetails(false);
			} catch (e) {
				setError(true);
				setLoadingRentalTaxDetails(false);
			}
		};
		fetchData();
	}, []);

	return { rentalTaxDetails, isLoadingRentalTaxDetails, errorRentalTaxDetails };
}


export {
    useRentals, useHandleRentalFormSubmit,useGetRentalTax, useGetPastTenantsByRentalProperty, useGetDashboardPaymentsData, useRentalUnits, usePropertyDashboardRevenueMonths, useGetRentalTicketsByProperty, useGetPaymentsByActivePeriodData, useGetRentalTickets,useGetTenantsByRentalProperty, useGetRentalTenants, useGetDashboardData, useGetPaymentsData, useGetLandlordPaymentsData, useGetLandlordInvoicesData, useGetMonthsData, useGetTenantOccupancyActivePeriodsData,  useGetLandlordExpenseData, useExpenseTypes, useExpenseDetails, useUnoccupiedRentalUnits, usePropertyDashboardStatistics, usePropertyDashboardCards, usePropertyDashboardRevenue
}