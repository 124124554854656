import * as React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { baseURL } from "services/API";
import { Grid, Box, Typography, TextField } from "@mui/material";
import TenantsTable from "components/Tables/TenantsTable";
import FormDialog from "components/modal";
import DummyTable from "components/Tables/DummyTable";
import ProgressScale from "components/ProgressScale";
import { useGetTenantsByRentalProperty, useRentals } from "services/hooks/rentals";
import { setRefreshCount, setOccupancyId } from "state";
import TopSnack from "components/TopSnack";
import TenantsPopover from "components/PopOvers/TenantsPopover";
import TenantMessageForm from "components/Forms/TenantMessageForm";
import TenantUpdateForm from "components/Forms/TenantUpdateForm";
import { useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import moment from "moment";
import * as yup from 'yup';


export default function PropertyTenants({property_id}) {
	const token = useSelector((state) => state.token);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	// const [rental, setRental] = React.useState("");
	const [unit, setUnit] = React.useState("");
	const [message, setMessage] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const [openTenantDetails, setOpenTenantDetails] = React.useState(false);
	const [openSnack, setOpenSnack] = React.useState({
		open: false,
		vertical: "top",
		horizontal: "center",
	});
	const [severity, setSeverity] = React.useState("");
	const { tenantsByProperty, isLoadingTenantsByProperty } = useGetTenantsByRentalProperty(property_id);
	// const { rentals, isLoadingRentals } = useRentals();
	const [loadingTenantsError, setLoadingTenantsError] = React.useState("");
	const [loadingTenantDetails, setLoadingTenantDetails] = React.useState(true);
	const [modal, setModal] = React.useState("");
	const [details, setDetails] = React.useState("");
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;
	const [endTenancyData, setEndTenancyData] = React.useState({
		unit_id: "",
		tenant_id: "",
		date_ended: moment().format('YYYY-MM-DD'),
		reason_ended: "",
	});
	const [errors, setErrors] = React.useState({});


	const handleClickOpen = () => {
		setLoadingTenantDetails(false);
		setLoadingTenantsError("");
		setOpen(true);
	};

	const handleClose = () => {
		setModal("");
		setOpen(false);
		setEndTenancyData({
			unit_id: "",
			tenant_id: "",
			date_ended: moment().format("YYYY-MM-DD"),
			reason_ended: "",
		});
	};

	const handleClickOpenTenantDetails = () => {
		setOpenTenantDetails(true);
	};


	const handleCloseSnack = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnack({ ...openSnack, open: false });
	};

	const handleClickPopOver = (event, row) => {
		setAnchorEl(event.currentTarget);
		setDetails(row)
	};

	const handleClosePopOver = () => {
		setAnchorEl(null);
	};

	const handleNavigateTenantPayments = (occupancy) => {
		dispatch(setOccupancyId(occupancy))
		navigate("/tenants/payments")
	}

	const endTenancySchema = yup.object().shape({
		unit_id: yup.number().required("This is required"),
		tenant_id: yup.number().required("This is required"),
		date_ended: yup.date().required("This is required"),
		reason_ended: yup.string().required("This is required"),
	})

	const handleChangeEndTenancyData = (event) => {
		const {name, value} = event.target;
		setEndTenancyData((prev) => ({ ...prev, [name]: value }));
		if (!value.trim()) {
		setErrors((prev) => ({ ...prev, [name]: `This is required`}));
		} else {
		setErrors((prev) => ({ ...prev, [name]: '' }));
		}

	}

	const handleSubmitEndTenancy = async () => {
		try {
			await endTenancySchema.validate({
				unit_id: details.related_rental_unit.id,
				tenant_id: details.related_tenant.id,
				date_ended:endTenancyData.date_ended,
				reason_ended:endTenancyData.reason_ended
			}, {abortEarly:false});
		} catch (error) {
			const newError = {}
			error.inner.forEach(err => {
				newError[err.path]=err.message
			})
			setErrors(newError)
			return
		}

		try {
			await axios.post(`${baseURL}/landlords/rentals/units/remove`, {
				unit_id: details.related_rental_unit.id,
				tenant_id: details.related_tenant.id,
				date_ended:endTenancyData.date_ended,
				reason_ended:endTenancyData.reason_ended
			});
			handleClose();
			dispatch(setRefreshCount())
			setErrors({});
		} catch (error) {
			console.log(error.response?.data?.error); 
		}
	}


	const handleModalView = (modalView) => {
		setModal(modalView);
	};

	return (
		<Box m="20px">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{isLoadingTenantsByProperty && <ProgressScale />}
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<Typography variant="h4" fontWeight={"bold"}>Current Tenants</Typography>
					</Box>
					<Grid container spacing={2}>
						{/* <Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
							<BasicSelect
								value={rental}
								handleChange={handleSelectChange}
								label={"Filter Rental"}
								data={rentals}
								isLoading={isLoadingRentals}
								id={"main-tenants-rental-select"}
							/>
						</Grid>
						<Grid item xs={12} sm={5} md={8} sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "end", alignItems: "center" }}>
							
						</Grid>
						<Grid item sx={{ display: { xs: "block", sm: "none" } }} xs={12} sm={4}>
							<BasicSelect
								value={rental}
								handleChange={handleSelectChange}
								label={"Filter Rental"}
								data={rentals}
								isLoading={isLoadingRentals}
								id={"main-tenants-rental-select"}
							/>
						</Grid> */}
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sx={{ marginTop: 3 }}>
							{isLoadingTenantsByProperty ? (
								<DummyTable />
							) : (
								<TenantsTable
									setDetails={setDetails}
									rows={tenantsByProperty}
									isLoadingRentalTenants={isLoadingTenantsByProperty}
									handleClickPopOver={handleClickPopOver}
									handleNavigateTenantPayments={handleNavigateTenantPayments}
									id={popperId}
								/>
							)}
						</Grid>
					</Grid>
					<TenantsPopover
						popperId={popperId}
						openPopper={openPopper}
						anchorEl={anchorEl}
						handleClickOpen={handleClickOpen}
						handleClosePopOver={handleClosePopOver}
						handleClickOpenTenantDetails={handleClickOpenTenantDetails}
						handleNavigateTenantPayments={handleNavigateTenantPayments}
						details={details}
						handleModalView={handleModalView}
						navigate={navigate}
					/>

					{modal === "edit" ? (
						<FormDialog
							title="Edit Tenant"
							enableActions={true}
							content={<TenantUpdateForm details={details} />}
							open={open}
							handleClose={handleClose}
							buttonText="update"
						/>
					) : modal === "message" ? (
						<FormDialog
							title={`Sending Message to ${details.related_tenant.first_name}`}
							enableActions={true}
							content={<TenantMessageForm />}
							open={open}
							handleClose={handleClose}
							buttonText="submit"
						/>
					) : modal === "delete" ? (
						<FormDialog
							title="End Tenancy"
							enableActions={true}
							open={open}
							content={
								<>
									<Typography color="red">Please input the following details to confirm the end of the tenancy</Typography>
									<TextField
										required
										id="outlined-required"
										label="Reason for Ending Tenancy"
										name="reason_ended"
										value={endTenancyData.reason_ended}
										multiline
										rows={3}
										onChange={handleChangeEndTenancyData}
										fullWidth={true}
										margin="dense"
										error={Boolean(errors.reason_ended)}
										helperText={errors.reason_ended}
										autoFocus
									/>
									<FlexBetween>
										<Typography> Date of Tenancy End:</Typography>
										<TextField
											required
											id="outlined-required"
											name="date_ended"
											type="date"
											label="Tenancy End Date"
											value={endTenancyData.date_ended}
											onChange={handleChangeEndTenancyData}
											sx={{ width:"50%" }}
											margin="dense"
											error={Boolean(errors.date_ended)}
											helperText={errors.date_ended}
										/>
									</FlexBetween>
									
								</>
							}
							handleSubmit={handleSubmitEndTenancy}
							handleClose={handleClose}
							buttonText="Yes, End Tenancy"
						/>
					): null}

					<TopSnack
						vertical={openSnack.vertical}
						horizontal={openSnack.horizontal}
						open={openSnack.open}
						handleCloseSnack={handleCloseSnack}
						message={message}
						severity={severity}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}
