import * as React from "react";
import { Grid, Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import BasicSelect from "components/select";
import ExpensesPopover from "components/PopOvers/ExpensesPopover";
import { formatDate } from "utilities/date";
import { useGetLandlordExpenseData, useRentals, useGetRentalTickets, useExpenseTypes } from "services/hooks/rentals";
import ProgressScale from "components/ProgressScale";
import DummyTable from "components/Tables/DummyTable";
import FormDialog from "components/modal";
import ExpensesTable from "components/Tables/ExpensesTable";
import ExpenseForm from "components/Forms/LandlordExpensesForm";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "services/API";
import PlainCard from "components/PlainCard";
import FlexBetween from "components/FlexBetween";
import { withoutSymbolFormatter } from "utilities/currencyFormatter";
import EditExpenseForm from "components/Forms/EditLandlordExpensesForm";
import ExpenseView from "components/views/expenseView";

export default function Expenses() {
	const [rental, setRental] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const [details, setDetails] = React.useState("");
	const landlord = useSelector((state)=>state.landlord);
	const [modal, setModal] = React.useState("");
	const { expenseData, isLoadingExpenseData, errorLoadingExpenseData, refetch } = useGetLandlordExpenseData();
	const [newExpenses, setNewExpenses] = React.useState({
		related_landlord_rental: 0,
		related_ticket: "",
		amount: "",
		expense_type:0,
		related_expense_detail:0,
		description: "",
	});
  	const [errors, setErrors] = React.useState({});
	const [isSubmittingData, setIsSubmittingData] = React.useState(false);
	const { rentals } = useRentals();
	const { expenseTypes} = useExpenseTypes();
	const { rentalTickets } = useGetRentalTickets();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;

	const [successMessage, setSuccessMessage] = React.useState("");
	const [errorMessage, setErrorMessage] = React.useState("");
	const [openSnack, setOpenSnack] = React.useState(false);

  	const handleChange = (event) => {
      const { name, value } = event.target;
      setNewExpenses((prev) => ({ ...prev, [name]: value }));
      if (!toString(value).trim()) {
        setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
      } else {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      }
    };

	const handleDeleteExpense = async () => {
    try {
      const response = await axios.delete( `${baseURL}/admin/landlord_expenses/details/${newExpenses.id}`);

      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSuccessMessage("Expense deleted successfully");
      refetch();
    } catch (error) {

      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setErrorMessage("Error deleting expense");
    }
    setOpen(false);
	setAnchorEl(null)
  };

	const validateForm = () => {
		const errors = {};

		if (newExpenses.related_landlord_rental===0) errors.related_landlord_rental = "Please select a property";
		if (!newExpenses.description.trim()) errors.description = "Expenses Desription is required";
		if (!newExpenses.amount.trim()) errors.amount = "Expenses Amount is required";
		if (newExpenses.related_expense_detail===0) errors.related_expense_detail = "Please select an expense";
		if (newExpenses.expense_type===0) errors.related_expense_detail = "Please select an expense category";

		setErrors(errors);
		return Object.keys(errors).length === 0;
	};

	const handleSubmitExpenses = async () => {

		if (!validateForm()) {
			setOpenSnack({
				open: true,
				message: "Please fill all fields",
				severity: "error",
			});
			return;
		}
		try {
			const response = await axios.post(`${baseURL}/admin/landlord_expenses`, newExpenses);
			setSuccessMessage(`Expenses added successfully`)
		
		} catch (error) {
			setErrorMessage(error.response?.data?.error)
		} finally {
			setNewExpenses({
				related_landlord_rental: 0,
				related_ticket: "",
				amount: "",
				expense_type:0,
				related_expense_detail:0,
				description: "",
			});
			refetch();
			setIsSubmittingData(false);
			handleClose();
		}
	}

	const handleSelectChange = (event) => {
		setRental(event.target.value);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleModalView = (modalView) => {
		setNewExpenses(details);
		setModal(modalView);
	};

	const handleClose = () => {
		setOpen(false);
		setNewExpenses({
			related_landlord_rental: 0,
			related_ticket: "",
			amount: "",
			expense_type:0,
			related_expense_detail:0,
			description: "",
		});
		setErrors({});
	};

	const handleClickPopOver = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClosePopOver = () => {
		setAnchorEl(null);
	};

	console.log(modal);


	return (
		<Box m="10px">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{isLoadingExpenseData && <ProgressScale />}
					<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<Typography variant="h2" fontWeight={"bold"}>Expenses</Typography>
					</div>
					{
						successMessage && 
						<p sx={{color: "green", fontSize: "14px"}}>{successMessage}</p>
					}
					{errorMessage && (
						<p style={{ color: "red", fontSize: "14px" }}>{errorMessage}</p>
					)}
					<FlexBetween sx={{ margin:"0.7rem" }}>
						<PlainCard name="Total Expense" value={withoutSymbolFormatter(parseFloat(expenseData.total))} />
					</FlexBetween>
					<Grid container spacing={2}>
						<Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
							<BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter By Rental"} data={rentals} />
						</Grid>
                        <Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
								<BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter By Month"} data={rentals} />
						</Grid>
                        <Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
                            <Button
								className="add-button"
								variant="contained"
								endIcon={<AddIcon />}
								onClick={handleClickOpen}
                            >
                                Create Expense
                            </Button>
                        </Grid>
						<Grid item xs={12} sm={5} md={8} sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "end", alignItems: "center" }}>
							{/* <Button className="add-button" variant="contained" onClick={handleClickOpen} endIcon={<AddIcon />}>
											Add Ticket
										</Button> */}
						</Grid>
						<Grid item sx={{ display: { xs: "block", sm: "none" } }} xs={12} sm={4}>
							<BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter Rental"} data={rentals} />
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sx={{ marginTop: 3 }}>
							{isLoadingExpenseData ? (
								<DummyTable />
							) : (
								<ExpensesTable
									data={expenseData.expenses}
									handleClickPopOver={handleClickPopOver}
									id={popperId}
									formatDate={formatDate}
									setDetails={setDetails}
								/>
							)}
						</Grid>
					</Grid>
					<ExpensesPopover
						popperId={popperId}
						openPopper={openPopper}
						anchorEl={anchorEl}
						handleClosePopOver={handleClosePopOver}
						handleClickOpen={handleClickOpen}
						details={details}
						handleModalView={handleModalView}
					/>
				</Grid>
				{modal === "view" ? (
						<FormDialog
							title={`View Expense`}
							// enableActions={true}
							content={<ExpenseView expenseDetail={details}/>}
							open={open}
							handleClose={handleClose}
							// buttonText="submit"

						/>
				) :modal === 'edit' ? (
					<FormDialog
						title="Edit Expense"
						enableActions={true}
						content={<EditExpenseForm 
									landlordProperties={rentals}
									newExpenses={newExpenses}
									errors={errors}
									expenseTypes={expenseTypes}
									handleFormChange={handleChange}
									rentalTickets={rentalTickets}
								/>}
						open={open}
						handleClose={handleClose}
						buttonText="Edit Expense"
					/>
				): modal === "delete" ? (
							<FormDialog
								title="Delete Expense"
								enableActions={true}
								open={open}
								content="Are you sure you want to delete this Expense?"
								handleClose={handleClose}
								buttonText="Delete Expense"
								handleSubmit={handleDeleteExpense}
								isSubmittingData={isSubmittingData}
							/>
				):(
					<FormDialog
						open={open}
						handleClose={handleClose}
						title={"Record New Expense"}
						enableActions={true}
						buttonText="RECORD EXPENSE"
						content={
							<ExpenseForm
								landlordProperties={rentals}
								newExpenses={newExpenses}
								errors={errors}
								expenseTypes={expenseTypes}
								handleFormChange={handleChange}
								rentalTickets={rentalTickets}
							/>
						}
						
						handleSubmit={handleSubmitExpenses}
						isSubmittingData={isSubmittingData}
					/>
				)}
			</Grid>
		</Box>
	);
}
