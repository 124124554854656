import React from "react";
import {Box,Button,Typography,Paper, TableHead, Divider,} from "@mui/material";
import { FlexBetween, FlexColCenter, FlexColLeft, FlexColRight, FlexEnd, FlexStart } from "components/FlexBox/FlexBox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {StyledTableCell,StyledTableRow,} from "components/Tables/TableCellandRow";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter"; 
import moment from "moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoicePdf from "components/pdf/invoicePdf";


const InvoiceView = ({invoiceDetail}) => {

  
  // const landlord_details = invoiceDetail.related_landlord;
  const invoice_item = invoiceDetail.invoice_description;
  const invoice_totals = invoiceDetail.invoice_amount;
  const invoice_to = invoiceDetail.related_occupancy.related_tenant;
  const invoice_to_location = invoiceDetail.related_occupancy.related_rental_unit.related_rental
  const has_vat = invoiceDetail.has_vat
  const payment_terms = invoiceDetail.payment_terms;
  const vat_percentage = invoiceDetail.vat_percentage
  const vat_amount = invoiceDetail.vat_amount

  return (
    <Box>
        
        <Box sx={{display: "flex", width:"100%", justifyContent:"center" }}>
          <Paper sx={{ p: "10px", display: "flex", flexDirection:"column", margin:"10px 0", width:"210mm", }}>
            <FlexBetween>
              <Typography variant="h1" sx={{ justifyContent:"flex-start", padding:"6px" }}>Invoice</Typography>
              <img src="../images/rent-beta-logo_dark.png" height="40px" width="200" />
            </FlexBetween>
            
            <FlexStart sx={{ marginBottom:"15px" }}>
              <FlexColLeft>
                <Typography fontWeight="bold">{invoice_to.first_name+' '+invoice_to.last_name}</Typography>
                <Typography>{invoice_to_location.rental_name}</Typography>
                <Typography>{invoice_to_location.location_county+', '+invoice_to_location.location}</Typography>
              </FlexColLeft>
            </FlexStart>

            <FlexBetween sx={{ marginBottom:"15px" }}>
              <FlexColLeft>
                <Typography fontWeight="bold">BILL TO</Typography>
                <Typography>{invoice_to.first_name+' '+invoice_to.last_name}</Typography>
                <Typography>{invoice_to_location.rental_name}</Typography>
                <Typography>{invoice_to_location.location_county+', '+invoice_to_location.location}</Typography>
              </FlexColLeft>
              <FlexColRight sx={{ width:"40%", marginRight:"5px" }}>
                <FlexBetween>
                  <Typography fontWeight="bold">INVOICE#</Typography>
                  <Typography>1</Typography>
                </FlexBetween>
                <FlexBetween>
                  <Typography fontWeight="bold">INVOICE DATE</Typography>
                  <Typography>{moment(invoiceDetail.date_created).format('DD/MM/YYYY')}</Typography>
                </FlexBetween>
                <FlexBetween>
                  <Typography fontWeight="bold">DUE DATE</Typography>
                  <Typography>{moment(invoiceDetail.date_created).format('DD/MM/YYYY')}</Typography>
                </FlexBetween>
              </FlexColRight>

            </FlexBetween>

                       

            <TableContainer sx={{ maxHeight: "100vh", marginBottom:"20px" }}>
              <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell  style={{ backgroundColor: "white",padding: "6px 6px",margin:"5px",borderBottom: "2px solid",}}>
                      <FlexColCenter><Typography variant="h5">QTY</Typography></FlexColCenter>
                    </StyledTableCell>
                    <StyledTableCell  style={{ backgroundColor: "white",padding: "6px 6px",margin:"5px",borderBottom: "2px solid",}}>
                      <FlexColCenter><Typography variant="h5">DESCRIPTION</Typography></FlexColCenter>
                    </StyledTableCell>
                    <StyledTableCell  style={{ backgroundColor: "white",padding: "6px 6px",margin:"5px",borderBottom: "2px solid",}}>
                      <FlexColCenter><Typography variant="h5">UNIT PRICE</Typography></FlexColCenter>
                    </StyledTableCell>
                    <StyledTableCell  style={{ backgroundColor: "white",padding: "6px 6px",margin:"5px",borderBottom: "2px solid",}}>
                      <FlexColCenter><Typography variant="h5">AMOUNT</Typography></FlexColCenter>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  
                  <StyledTableRow>
                    <StyledTableCell style={{padding: "1px 1px",border: "1px",}}>
                      <FlexColCenter><Typography variant="h6">1</Typography></FlexColCenter>
                    </StyledTableCell>
                    <StyledTableCell style={{padding: "1px 1px",border: "1px",}}>
                      <FlexColCenter><Typography variant="h6">{invoice_item}</Typography></FlexColCenter>
                    </StyledTableCell>
                    <StyledTableCell style={{padding: "1px 1px",border: "1px",}}>
                      <FlexColCenter><Typography variant="h6">{withoutSymbolFormatter(parseFloat(invoice_totals))}</Typography></FlexColCenter>
                    </StyledTableCell>
                    <StyledTableCell style={{padding: "1px 1px",border: "1px",}}>
                      <FlexColCenter><Typography variant="h6">{withoutSymbolFormatter(parseFloat(invoice_totals))}</Typography></FlexColCenter>
                    </StyledTableCell>
                  </StyledTableRow>  
                  <StyledTableRow>
                    <StyledTableCell style={{padding: "1px 1px",border: "1px", borderBottom:"2px solid"}}>
                      <FlexColCenter><Typography variant="h5"></Typography></FlexColCenter>
                    </StyledTableCell>
                    <StyledTableCell style={{padding: "1px 1px",border: "1px", borderBottom:"2px solid"}}>
                      <FlexColCenter><Typography variant="h5"></Typography></FlexColCenter>
                    </StyledTableCell>
                    <StyledTableCell style={{padding: "1px 1px",border: "1px", borderBottom:"2px solid"}}>
                      <FlexColCenter><Typography variant="h5" fontWeight="bold">TOTAL</Typography></FlexColCenter>
                    </StyledTableCell>
                    <StyledTableCell style={{padding: "1px 1px",border: "1px", borderBottom:"2px solid"}}>
                      <FlexColCenter><Typography variant="h5" fontWeight="bold">{currencyFormatter(parseFloat(invoice_totals))}</Typography></FlexColCenter>
                    </StyledTableCell>
                  </StyledTableRow>          
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ display:"flex", justifyContent:"end" }}>
              <Typography fontWeight="bold" marginRight="10px">VAT AMOUNT({parseFloat(vat_percentage)}%)</Typography>
              <Typography>{currencyFormatter(parseFloat(vat_amount))}</Typography>
            </Box>
            <Box sx={{ display:"flex", justifyContent:"end" }}>
              <Typography fontWeight="bold" marginRight="10px">OVERALL TOTAL</Typography>
              <Typography>{currencyFormatter(parseFloat(vat_amount)+parseFloat(invoice_totals))}</Typography>
            </Box>
            <FlexColLeft>
              <Typography  fontWeight="bold">PAYMENT TERMS</Typography>
              <Typography>{payment_terms}</Typography>
              <Divider/>
            </FlexColLeft>
            <FlexStart sx={{ marginTop:"10px" }}>
              <Typography  fontWeight="bold">PREPARED BY................................  DATE ...................................</Typography>
            </FlexStart>
        </Paper>
        </Box>
        <PDFDownloadLink document={<InvoicePdf viewData={invoiceDetail} />} fileName={`Invoice #${invoiceDetail.id}`}>
          <Button size="small" variant="contained" sx={{width: "20%",my: "15px",mx: "5px", }}>Download</Button>
        </PDFDownloadLink>
      </Box>
  );
};

export default InvoiceView;
