import React from "react";
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper,TablePagination,IconButton,Menu,MenuItem,Typography, tableCellClasses} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#f1f1f5",
		color: "#000",
		fontSize: 15,
		fontWeight: 700,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 16,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
  },
  // // hide last border
  // "&:last-child td, &:last-child th": {
  // 	border: 0,
  //
}));

const ExpensesTable = (props) => {
  const {data = [],onMenuClick} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleMenuClose = (action) => {
    setAnchorEl(null);
    if (selectedItem) {
      onMenuClick(action, selectedItem);
    }
  };

  const handleAction = (event, rowid, details) => {
    props.setDetails(details);
    props.handleClickPopOver(event, rowid);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Expense Name</StyledTableCell>
              <StyledTableCell>Expense Type</StyledTableCell>
              <StyledTableCell>Date </StyledTableCell>
              <StyledTableCell>Amount</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { data.length > 0 ? data.map((item, index) => (
              <TableRow key={index}>
                <StyledTableCell><Typography variant="body1" sx={{ fontSize: "14px" }}>  {item.related_expense_detail.expense_detail}</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body1" sx={{ fontSize: "14px" }}>  {item.related_expense_detail.related_expense_type.expense_type_name}</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body1" sx={{ fontSize: "14px" }}>  {moment(item.date).format("LLL")}</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body1" sx={{ fontSize: "14px" }}>  {item.amount}</Typography></StyledTableCell>
                <StyledTableCell><IconButton color="primary" size="small" aria-describedby={props.id} onClick={(e) => handleAction(e, item.id, item)}>  <MoreHorizIcon /></IconButton></StyledTableCell>
              </TableRow>
            )):(
              <StyledTableRow>
                <StyledTableCell colSpan={6}>No Data Availalble</StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      
    </>
  );
};

export default ExpensesTable;
