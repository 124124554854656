import React, {useState, useEffect} from "react";
import axios from "axios";
import { baseURL } from "services/API";
import UnoccupiedUnitsTable from "../../components/Tables/UnoccupiedUnitsTable";
import DummyTable from "components/Tables/DummyTable";
import { Grid, Box, Typography } from "@mui/material";
import { useUnoccupiedRentalUnits } from "services/hooks/rentals";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressScale from "components/ProgressScale";
import UnoccupiedUnitsPopover from "components/PopOvers/UnoccupiedUnitsPopover";
import FormDialog from "components/modal";
import AddTenantsFromUnoccupiedForm from "components/Forms/AddTenantsFromUnoccupiedListForm";
import * as yup from 'yup';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshCount } from "state";

const UnoccupiedUnits = () => {
  const navigate = useNavigate();

	const [activeUnit, setActiveUnit] = useState("")
	const [modal, setModal] = React.useState("");
	const dispatch = useDispatch();
	const property_id = useSelector((state) => state.property_id);
	const [anchorEl, setAnchorEl] = useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;
	const [details, setDetails] = React.useState("");
	const [open, setOpen] = React.useState(false);
  	const { unoccupiedRentalUnits, isLoadingUnoccupiedRentalUnits, error } = useUnoccupiedRentalUnits(property_id);
	const [unit, setUnit] = React.useState("");
	const [message, setMessage] = React.useState("");
	const [openSnack, setOpenSnack] = React.useState({
		open: false,
		vertical: "top",
		horizontal: "center",
	});
	const [severity, setSeverity] = React.useState("");
	const [isLoadedUnits, setIsLoadedUnits] = React.useState(false);
	const [units, setUnits] = React.useState([]);
	const [tenantDetails, setTenantDetails] = React.useState({});
	const [propertyDetails, setPropertyDetails] = useState({});
	const [loadingPropertyDetails, setLoadingPropertyDetails] = useState(false);
	const [loadedTenant, setLoadedTenant] = React.useState(false);
	const [loadingTenantsError, setLoadingTenantsError] = React.useState("");
	const [isExistingTenant, setIsExisitingTenant] = React.useState(true);
	const [loadingTenantDetails, setLoadingTenantDetails] = React.useState(true);
	const [errorLoadingTenantDetails, setErrorLoadingTenantDetails] = React.useState(false);
	const [newTenantDetails, setNewTenantDetails] = React.useState({
		rent_deposit_amount: 0, 
		months_covered:1, 
		next_payment_date:moment().format('YYYY-MM-DD'),
		first_name: "",
		last_name: "",
		tenant_number: "",
		phone_number:"",
		unit_id: "",
	});

	const fetchDetails = async () => {
		try {
			setLoadingPropertyDetails(true);
			const response = await axios.get(`${baseURL}/landlords/rentals/details?rental_id=${property_id}`);
			setPropertyDetails(response.data.data);
			setLoadingPropertyDetails(false);
		} catch (e) {
			setPropertyDetails(true);
			setLoadingPropertyDetails(false);
		}
	}
	
	useEffect(() => {
		fetchDetails()
	}, [])

	const handleClickOpen = () => {
		setOpen(true);
	}

	const handleClickPopOver = (event, row) => {
		setAnchorEl(event.currentTarget);
		setActiveUnit(row);
	};

	const handleClosePopOver = () => {
		setAnchorEl(null);
	};

	const handleModalView = (modalView) => {
		if(modalView==='addTenant'){
			setNewTenantDetails((prev) => ({ ...prev, "unit_id": details.id,"rent_deposit_amount": details.unit_rent}));
		}
		setModal(modalView);
	};

	const handleClose = () => {
		setModal("");
		setErrors({});
		setOpen(false);
		setLoadedTenant(false);
		setIsExisitingTenant(true);
		setIsLoadedUnits(false);
		setNewTenantDetails({
			rent_deposit_amount: 0, 
			months_covered:1, 
			next_payment_date:moment().format('YYYY-MM-DD'),
			first_name: "",
			last_name: "",
			tenant_number: "",
			phone_number:"",
			unit_id: "",
		});
	};

	const handleChangeTenantDetails = (event) => {
		const { name, value } = event.target;
    	setNewTenantDetails((prev) => ({ ...prev, [name]: value }));
		if (!toString(value).trim()) {
      		setErrors((prev) => ({ ...prev, [name]: `${name} is required`}));
		} else {
			setErrors((prev) => ({ ...prev, [name]: '' }));
		}
	};

	const handleChangeTenantNumber = async (e) => {
		setLoadedTenant(false);
		setIsExisitingTenant(true);
		setIsLoadedUnits(false);
		setLoadingTenantsError("");
		const phone_number = e.target.value;
		if (!phone_number.trim()) {
      		setErrors((prev) => ({ ...prev, 'phone_number': `The Tenant's phone number is required`}));
		} else {
			setErrors((prev) => ({ ...prev, 'phone_number': '' }));
		}
		if (phone_number.length === 12) {
			setNewTenantDetails((prev) => ({ ...prev, 'phone_number': phone_number }));
			try {
				const response = await axios.get(`${baseURL}/tenants?username=${phone_number}`);
				setTenantDetails(response.data.data);
				setNewTenantDetails((prev)=> ({...prev, tenant_number: response.data.data.tenant_number}))
				setLoadedTenant(true);
				setLoadingTenantDetails(false);
				setLoadingTenantsError("");
				return true;
			} catch (err) {
				setIsExisitingTenant(false);
				setLoadingTenantDetails(false);
				setErrorLoadingTenantDetails(true);
				setLoadingTenantsError("");
				return false;
			}
		} else {
			setLoadingTenantsError("Please type a complete phone number with 12 digits including 256");
			
			// setNewTenantDetails((prev) => ({ ...prev, "unit_id": "","rent_deposit_amount": undefined,"months_covered": undefined, }));
		}
	};

	const handleSubmitDelete = async () => {
    try {
      const response = await axios.delete(`${baseURL}/landlords/rentals/units/delete?unit_id=${activeUnit}`);

      setOpenSnack({ ...openSnack, open: true });
      setSeverity("success");
      setMessage("Unit Deleted Successfully");
      dispatch(setRefreshCount());
    } catch (err) {
      setOpenSnack({ ...openSnack, open: true });
      setSeverity("error");
      setMessage("Unit Delete failed, Please check unit details");
    }
  };

	const [errors, setErrors] = React.useState({});
	const propertySchema = isExistingTenant ? yup.object().shape({
		unit_id: yup.string().required("Please select a unit attached to the property above"),
		tenant_number: yup.string().required("Input a the tenant's phone number to start the process"),
		rent_deposit_amount: yup.number().required("This field is required"),
		months_covered: yup.number().required("This field is required"),
		next_payment_date: yup.date().required("This field is required"),
	}) : yup.object().shape({
		unit_id: yup.string().required("Please select a unit attached to the property above"),
		// tenant_number: yup.string().required("Input a the tenant's phone number to start the process"),
		first_name: yup.string().required("Input the tenant's First Name"),
		last_name: yup.string().required("Input the tenant's Last Name"),
		rent_deposit_amount: yup.number().required("This field is required"),
		months_covered: yup.number().required("This field is required"),
		next_payment_date: yup.date().required("This field is required"),

	}) ;

	const handleSubmitTenant = async () => {
		try {
			await propertySchema.validate(newTenantDetails, {abortEarly:false});
		} catch (error) {
			const newError = {}
			error.inner.forEach(err => {
				newError[err.path]=err.message
			})
			setErrors(newError)
			console.log(newError)
			return
		}
		
		try {
			if (isExistingTenant) {
				await axios.post(`${baseURL}/landlords/rentals/units/assign`, {
					unit_id: newTenantDetails.unit_id,
					tenant_number: newTenantDetails.tenant_number,
					rent_deposit_amount: newTenantDetails.rent_deposit_amount, 
					months_covered:newTenantDetails.months_covered, 
					next_payment_date:newTenantDetails.next_payment_date,
				});
			} else {
				const response = await axios.post(`${baseURL}/accounts/users`, {
					first_name: newTenantDetails.first_name,
					last_name: newTenantDetails.last_name,
					email: "dummy@email.com",
					phone_number: newTenantDetails.phone_number.slice(3),
					password: "@Rent123",
					username: `+${newTenantDetails.phone_number}`,
					to_activate: "True",
					unit_id: newTenantDetails.unit_id,
					rent_deposit_amount: newTenantDetails.rent_deposit_amount, 
					months_covered:newTenantDetails.months_covered, 
					next_payment_date:newTenantDetails.next_payment_date,
				});
			}
			setOpen(false);
			setOpenSnack({ ...openSnack, open: true});
			setSeverity("success");
			setMessage("Tenant Added Successfully");
			// dispatch(setRefreshCount());
			setLoadedTenant(false);
			setIsExisitingTenant(true);
			setIsLoadedUnits(false);
			setNewTenantDetails({
				rent_deposit_amount: 0, 
				months_covered:1, 
				next_payment_date:moment().format('YYYY-MM-DD'),
				first_name: "",
				last_name: "",
				tenant_number: "",
				phone_number:"",
				unit_id: "",
			});
		} catch (err) {
			setOpenSnack({ ...openSnack, open: true});
			setSeverity("error");
			setMessage("Tenant Addition failed, Please check data provided");
		}
	};


	return (
		<Box m="20px">
			<Grid container spacing={2}>
				<Grid item xs={12} spacing={2}>
					{isLoadingUnoccupiedRentalUnits && <ProgressScale />}
					<div style={{ display: "block" }}>
						<Typography variant="h4" fontWeight={"bold"}>Unoccupied Units for Property {propertyDetails.rental_name}</Typography>
					</div>
					<Grid container>
						<Grid container>
							{/* <Grid container spacing={2}>
								<Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
									<BasicSelect value={unitName} label={"Properties"} data={rentalUnits} />
								</Grid>
								<Grid item xs={12} sm={5} md={8} sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "end", alignItems: "center" }}>
									<Button className="add-button" variant="contained" onClick={handleClickOpen} endIcon={<AddIcon />}>
										Add Property Unit
									</Button>
								</Grid>
								<Grid item sx={{ display: { xs: "block", sm: "none" } }} xs={12} sm={4}>
									<BasicSelect value={unitName} label={"Properties"} data={rentalUnits} />
								</Grid>
							</Grid> */}
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12} sx={{ marginTop: 3 }}>
								{isLoadingUnoccupiedRentalUnits ? 
									<DummyTable /> : 
									<UnoccupiedUnitsTable rows={unoccupiedRentalUnits} isLoadingUnoccupiedRentalUnits={isLoadingUnoccupiedRentalUnits} handleClickPopOver={handleClickPopOver}
									id={popperId} setDetails={setDetails}/>
								}
							</Grid>
						</Grid>

						<UnoccupiedUnitsPopover
							popperId={popperId}
							openPopper={openPopper}
							anchorEl={anchorEl}
							handleClosePopOver={handleClosePopOver}
							handleClickOpen={handleClickOpen}
							navigate={navigate}
							activeUnit={activeUnit}
							handleModalView={handleModalView}
						/>
					</Grid>
				</Grid>
			</Grid>
			{modal === "addTenant" ? (
				<FormDialog
					open={open}
					handleClose={handleClose}
					enableActions={true}
					content={
						<AddTenantsFromUnoccupiedForm
							handleChangeTenantNumber={handleChangeTenantNumber}
							tenant={tenantDetails}
							errorTenant={errorLoadingTenantDetails}
							loadedTenant={loadedTenant}
							errors={errors}
							unit={details}
							// handleChangeRental={handleSelectChange}
							isExistingTenant={isExistingTenant}
							handleChangeTenantDetails={handleChangeTenantDetails}
							loadingTenantsError={loadingTenantsError}
						/>
					}
					title={`Attach Tenant to ${details.unit_name}`}
					handleSubmit={handleSubmitTenant}
					buttonText="submit"
					buttonClass={"add-button"}
				/>
			) :  modal === "delete" ? (
				<FormDialog
					title="Delete Unit"
					enableActions={true}
					open={open}
					content="Are you sure you want to delete this Unit?"
					handleClose={handleClose}
					handleSubmit={handleSubmitDelete}
					buttonText="Delete Unit"
				/>
			) :  null}
		</Box>
	);
};

export default UnoccupiedUnits;
